import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { NotifyContext } from '../../contexts/NotifyContext';

export default function VideoUploader({ updateVideos }) {
  const addNotify = useContext(NotifyContext);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const changeFileHandler = (e) => {
    const fileInput = e.target?.files[0];
    if (fileInput.type === 'video/mp4') {
      setFile(fileInput);
    }
  };

  const createFileToVimeo = async () => {
    if (fileName === '') return
    setLoading(true);
    try {
      const response = await fetch('https://api.vimeo.com/me/videos', {
        method: 'POST',
        body: JSON.stringify({
          upload: {
            approach: 'tus',
            size: file.size,
          },
          name: `Видео-решение: ${fileName}`,
        }),
        headers: {
          Authorization: 'Bearer 03c2d99add48b701818c08d2c9673d2c',
          'Content-Type': 'application/json',
          Accept: 'application/vnd.vimeo.*+json;version=3.4',
        },
      });
      const data = await response.json();
      
      if (response.status !== 200) throw new Error(data.error);
      console.log('success create', data);
      uploadFileToVimeo(data.upload.upload_link);
    } catch (e) {
      addNotify('Ошибка при загрузке', 'error');
      console.warn('Fetch error createVideo', e.message);
      setLoading(false);
    }
  };

  const uploadFileToVimeo = (link) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PATCH', link, true);
    console.log('start uploading vimeo', xhr);

    xhr.setRequestHeader('Tus-Resumable', '1.0.0');
    xhr.setRequestHeader('Upload-Offset', '0');
    xhr.setRequestHeader('Content-Type', 'application/offset+octet-stream');
    xhr.setRequestHeader('Accept', 'application/vnd.vimeo.*+json;version=3.4');

    xhr.onload = (data) => {
      addNotify('Видео успешно загружено', 'success');
      console.log(data);
      updateVideos()
      setLoading(false);
    };
    xhr.onerror = (error) => {
      addNotify('Ошибка при загрузке', 'error');
      console.warn('Fetch error uploadVideo', error);
      setLoading(false);
    };
    xhr.send(file);
  };

  return (
    <Stack spacing={2}>
      <TextField
        error={!fileName}
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        fullWidth
        placeholder="Введите название видео (id вопроса)"
        required
      />
      <Box>
        <Button variant="outlined" fullWidth component="label" sx={{ textTransform: 'none' }}>
          {file ? file.name : 'Выберите видео mp4'}
          <input onChange={changeFileHandler} type="file" hidden />
        </Button>
        <LoadingButton
          loading={loading}
          onClick={createFileToVimeo}
          disabled={!file}
          variant="contained"
          fullWidth
          sx={{ marginTop: 1 }}
        >
          Загрузить
        </LoadingButton>
      </Box>
    </Stack>
  );
}
