import { MathJaxContext } from 'better-react-mathjax';
import { useContext, useEffect, useState } from 'react';
// material
import {
  Autocomplete,
  Card,
  Container,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
import ImageManager from '../components/imageManager/ImageManager';
import MathTypeEditor from '../components/MathTypeEditor';
import Page from '../components/Page';
import {
  QuestionListHead,
  QuestionListToolbar,
  QuestionRowEnt,
  QuestionRowEntContext,
  QuestionRowNuet,
} from '../components/questions';
import SearchNotFound from '../components/SearchNotFound';
import UpdateAlert from '../components/UpdateAlert';
import { AuthContext } from '../contexts/AuthContext';
import { NotifyContext } from '../contexts/NotifyContext';
import { useAjax } from '../hooks/useAjax';
import { getColor } from '../utils/colorArray';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'subject', label: 'Предмет', alignRight: false },
  { id: 'themeID', label: 'ID темы', alignRight: false },
  { id: 'question', label: 'Вопрос', alignRight: false },
  { id: '' },
];

const SUBJECTS_WITHOUT_LANG = [15, 18, 19, 20, 21];

const rowsPerPage = 36;
const localActiveTest = 'active-test';
const localActiveSubject = 'active-subject';
const localLangQuestions = 'lang-questions';
const localActiveProbe = 'active-probe';

export default function Questions() {
  const user = useContext(AuthContext);
  const meta = user?.meta;

  const [test, setTest] = useState(localStorage.getItem(localActiveTest) || 'ent');
  const [activeSubject, setActiveSubject] = useState(0);
  const [probes, setProbes] = useState([]);
  const [activeProbe, setActiveProbe] = useState(+localStorage.getItem(localActiveProbe) || 1);

  const [errorFetch, setErrorFetch] = useState(false);
  const [quesLength, setQuesLength] = useState(0);
  const [questionsData, setQuestionsData] = useState([]);
  const [loadingQuestions, fetchQuestionsData] = useAjax((data, error) => {
    if (error) {
      setErrorFetch(true);
      console.warn('Fetch error questions', data.error);
      return;
    }
    console.log(data);
    setErrorFetch(false);
    setQuestionsData(data.questions);
    setQuesLength(data.length);
    setProbes(data.probes || []);
  });
  const getQuestionsData = () => {
    setQuestionsData([]);
    if (test === 'nuet')
      fetchQuestionsData(
        `ques?limit=${rowsPerPage}&length&offset=${
          page * rowsPerPage
        }&test=nuet&subject=${activeSubject}&order=${order}`
      );
    else if (test === 'ent')
      fetchQuestionsData(
        `ques?limit=${rowsPerPage}&length&offset=${
          page * rowsPerPage
        }&test=ent&subject=${activeSubject}&order=${order}&probes&probe=${activeProbe}&lang=${getLang()}`
      );
    else if (test === 'schools')
      fetchQuestionsData(
        `https://tests3.jaryq-academy.kz/api/ques/054t2kfw1?limit=${rowsPerPage}&length&offset=${
          page * rowsPerPage
        }&test=schools&subject=${activeSubject}&order=${order}&probes&probe=${activeProbe}&lang=${getLang()}`,
        'GET',
        null,
        {},
        'json',
        true
      );
  };

  const [subjectsInfo, setSubjectsInfo] = useState({
    subjects: [],
    themes: [],
  });
  const [loadingSubjects, fetchSubjectsInfo] = useAjax((data, error) => {
    console.log(data);
    if (error) {
      setErrorFetch(true);
      console.warn('Fetch error subjects');
      return;
    }
    setErrorFetch(false);

    // const filteredSubjects = data.subjects.filter((subj) => subj.test === test);
    // const filteredThemes = data.themes.filter((theme) => filteredSubjects.map((x) => x.id).includes(theme.parent));
    const filteredThemes = [];
    data.map((s) => filteredThemes.push(...s.themes));

    setSubjectsInfo({
      subjects: data,
      themes: filteredThemes,
    });
    setActiveSubject(+localStorage.getItem(localActiveSubject) || data[0].id);
    console.log({
      subjects: data,
      themes: filteredThemes,
    });
  });
  const getSubjectsInfo = () => fetchSubjectsInfo(`subjects/themes?test=${test === 'schools' ? 'ent' : test}`);
  const changeSubjectHandler = (e) => {
    setActiveSubject(e.target.value);
    setActiveProbe(probes.length ? probes[0] : 1);
    localStorage.setItem(localActiveSubject, e.target.value);
  };

  /* OPTIONS */
  const [lang, setLang] = useState(localStorage.getItem(localLangQuestions) || 'ru');
  const getLang = () => {
    if (SUBJECTS_WITHOUT_LANG.includes(activeSubject)) return '';
    return lang;
  };
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [filterName, setFilterName] = useState('');
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  useEffect(() => {
    getQuestionsData();
    console.log('update questions');
  }, [page, activeSubject, test, order, activeProbe, lang]);

  useEffect(() => {
    getSubjectsInfo();
    console.log('update subjects');
    localStorage.setItem(localActiveTest, test);
  }, [test]);

  /* NEW QUESTION */
  const [queModal, setQueModal] = useState(false);
  const [contextModal, setContextModal] = useState(false);

  const addNotify = useContext(NotifyContext);
  const [loadingUpdateQue, fetchUpdateQue] = useAjax((data, error) => {
    console.log(data);
    if (error) {
      addNotify(data.msg || 'Ошибка при обновлении', 'error');
      console.warn(data.error);
      return;
    }
    addNotify(data.msg || 'Вопрос обновлен', 'success');
    getQuestionsData();
    setQueModal(false);
    setContextModal(false);
  });
  const handleUpdateQuestion = (data) => {
    if (test === 'schools') {
      fetchUpdateQue(`https://tests3.jaryq-academy.kz/api/ques/054t2kfw1?test=${test}`, 'PUT', data, {}, 'json', true);
      return;
    }

    fetchUpdateQue(`ques?test=${test}`, 'PUT', data);
  };
  const handleAddQuestion = (data) => {
    if (test === 'schools') {
      fetchUpdateQue(`https://tests3.jaryq-academy.kz/api/ques/054t2kfw1?test=${test}`, 'POST', data, {}, 'json', true);
      return;
    }

    fetchUpdateQue(`ques?test=${test}`, 'POST', data);
  };

  const getThemeID = () => {
    if (test === 'schools') return -1;

    const filtered = subjectsInfo.themes.filter((x) => x.parent === activeSubject);
    if (filtered.length) return filtered[0].id;
    return -1;
  };

  const getInitialProbeNumber = () => {
    if (questionsData.length)
      return (
        questionsData.filter((x) => x.themeID !== 0).sort((a, b) => b.probeNumber - a.probeNumber)[0]?.probeNumber + 1
      );
    return 1;
  };

  return (
    <Page title="Questions">
      <MathTypeEditor />
      <MathJaxContext>
        <Modal disableEnforceFocus open={queModal}>
          <Container
            maxWidth="lg"
            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          >
            <IconButton onClick={() => setQueModal(false)} sx={{ ...closeModalStyle }}>
              <Iconify icon="eva:close-fill" width={24} height={24} />
            </IconButton>
            <Card sx={{ padding: 3, background: '#e1f5fe', overflow: 'visible' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  {(test === 'ent' || test === 'schools') && (
                    <QuestionRowEnt
                      headless="true"
                      id={0}
                      probe={activeProbe}
                      probeNumber={getInitialProbeNumber()}
                      subjectID={activeSubject}
                      themeID={getThemeID()}
                      question={test === 'ent' ? 'Новый вопрос ЕНТ' : 'Новый вопрос'}
                      answers={['Введите ответ']}
                      correct={[]}
                      solution={''}
                      disable={false}
                      lang={getLang()}
                      subjectsInfo={subjectsInfo}
                      loading={loadingUpdateQue}
                      handleSendData={handleAddQuestion}
                    />
                  )}
                  {test === 'nuet' && (
                    <QuestionRowNuet
                      headless="true"
                      id={0}
                      subjectID={activeSubject}
                      themeID={getThemeID()}
                      question={'Новый вопрос НУЕТ'}
                      answers={['Введите ответ']}
                      correct={[]}
                      solution={'Решение для вопроса'}
                      disable={false}
                      subjectsInfo={subjectsInfo}
                      loading={loadingUpdateQue}
                      handleSendData={handleAddQuestion}
                    />
                  )}
                </tbody>
              </table>
            </Card>
          </Container>
        </Modal>

        <Modal disableEnforceFocus open={contextModal}>
          <Container
            maxWidth="lg"
            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          >
            <IconButton onClick={() => setContextModal(false)} sx={{ ...closeModalStyle }}>
              <Iconify icon="eva:close-fill" width={24} height={24} />
            </IconButton>
            <Card sx={{ padding: 3, background: '#e1f5fe', overflow: 'visible' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <QuestionRowEntContext
                    headless="true"
                    id={0}
                    probe={activeProbe}
                    subjectID={activeSubject}
                    question={'Новый контекст'}
                    disable={false}
                    lang={getLang()}
                    subjectsInfo={subjectsInfo}
                    loading={loadingUpdateQue}
                    handleSendData={handleAddQuestion}
                  />
                </tbody>
              </table>
            </Card>
          </Container>
        </Modal>

        <ImageManager />
        <TableStyles />
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" spacing={3} sx={{ mb: 5 }}>
            <Typography variant="h4">База вопросов</Typography>

            <ToggleButtonGroup
              color="primary"
              value={test}
              exclusive
              onChange={(e, newState) => {
                if (newState) {
                  setTest(newState);
                  setPage(0);
                }
              }}
            >
              <ToggleButton disabled={!['superadmin', 'editor-ent'].includes(meta?.role?.slug)} value="ent">
                ЕНТ
              </ToggleButton>
              <ToggleButton disabled={!['superadmin', 'easytest'].includes(meta?.role?.slug)} value="schools">
                EasyTest
              </ToggleButton>
              <ToggleButton disabled={!['superadmin', 'editor-ent'].includes(meta?.role?.slug)} value="nuet">
                НУЕТ
              </ToggleButton>
            </ToggleButtonGroup>

            <TextField
              disabled={loadingSubjects}
              sx={{ width: '30ch' }}
              select
              label="Предмет"
              value={activeSubject || ''}
              onChange={changeSubjectHandler}
            >
              {subjectsInfo.subjects.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {(test === 'ent' || test === 'schools') && (
              <Autocomplete
                disablePortal
                value={activeProbe.toString()}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setActiveProbe(newValue.id);
                    localStorage.setItem(localActiveProbe, newValue.id);
                  }
                }}
                options={probes.map((option) => ({
                  id: option,
                  label: `Пробник №${option}`,
                }))}
                sx={{ width: '30ch', display: 'inline-block' }}
                renderInput={(params) => <TextField {...params} label="Номер пробника" />}
                isOptionEqualToValue={(option, value) => option.label.includes(value)}
                filterOptions={(options, value) =>
                  options.sort((a, b) => a.id - b.id).filter((x) => x.label.includes(value.inputValue))
                }
              />
            )}

            {(test === 'ent' || test === 'schools') &&
              !SUBJECTS_WITHOUT_LANG.includes(activeSubject) /* for English */ && (
                <ToggleButtonGroup
                  color="secondary"
                  value={lang}
                  exclusive
                  onChange={(e, newState) => {
                    if (newState) {
                      setLang(newState);
                      localStorage.setItem(localLangQuestions, newState);
                    }
                  }}
                >
                  <ToggleButton value="ru">RU</ToggleButton>
                  <ToggleButton value="kz">KZ</ToggleButton>
                </ToggleButtonGroup>
              )}
          </Stack>

          <UpdateAlert
            msg={
              <Typography variant="p">
                !Теперь после перезагрузки или смены страницы последние выбранные вами предмет, пробник и язык останутся
                те же
              </Typography>
            }
          />

          <Card>
            <QuestionListToolbar
              handlerRefresh={() => {
                getQuestionsData();
                getSubjectsInfo();
                console.log('refresh');
              }}
              filterName={filterName}
              onFilterName={handleFilterByName}
              loading={loadingQuestions}
              error={errorFetch}
              order={order}
              setOrder={() => {
                if (order === 'desc') setOrder('asc');
                else setOrder('desc');
              }}
              test={test}
              handleAddQuestion={() => setQueModal(true)}
              handleAddContext={() => setContextModal(true)}
            />
            <TableContainer sx={{ minWidth: 800 }}>
              <Table className="questionsTable">
                <QuestionListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {loadingQuestions && loadingSubjects ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    questionsData.map((row) => {
                      if (row.themeID === 0 && (test === 'ent' || test === 'schools'))
                        return (
                          <QuestionRowEntContext
                            key={row.id}
                            id={row.id}
                            indicator={getColor(row.id)}
                            probe={row.probe}
                            subjectID={row.subjectID}
                            question={row.question}
                            disable={row.disable}
                            lang={row.lang}
                            solution={row.solution}
                            loading={loadingUpdateQue}
                            subjectsInfo={subjectsInfo}
                            handleSendData={handleUpdateQuestion}
                          />
                        );
                      if (test === 'ent' || test === 'schools')
                        return (
                          <QuestionRowEnt
                            softDelete={test === 'ent'}
                            key={row.id}
                            id={row.id}
                            indicator={getColor(row.contextID)}
                            probe={row.probe}
                            probeNumber={row.probeNumber}
                            subjectID={row.subjectID}
                            themeID={row.themeID}
                            question={row.question}
                            answers={JSON.parse(row.answers || '[]')}
                            correct={JSON.parse(row.correct || '[]')}
                            solution={row.solution}
                            disable={!!row.disable}
                            lang={row.lang}
                            subjectsInfo={subjectsInfo}
                            loading={loadingUpdateQue}
                            handleSendData={handleUpdateQuestion}
                          />
                        );
                      if (test === 'nuet')
                        return (
                          <QuestionRowNuet
                            key={row.id}
                            id={row.id}
                            subjectID={row.subjectID}
                            themeID={row.themeID}
                            question={row.question}
                            answers={JSON.parse(row.answers || '[]')}
                            correct={JSON.parse(row.correct || '[]')}
                            solution={row.solution}
                            disable={!!row.disable}
                            subjectsInfo={subjectsInfo}
                            loading={loadingUpdateQue}
                            handleSendData={handleUpdateQuestion}
                          />
                        );

                      return null;
                    })
                  )}
                </TableBody>

                {!quesLength && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound empty={quesLength === 0} error={errorFetch} searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              <TablePagination
                component="div"
                count={quesLength}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableContainer>
          </Card>
        </Container>
      </MathJaxContext>
    </Page>
  );
}

const closeModalStyle = {
  position: 'absolute',
  right: 40,
  top: 10,
  zIndex: 2,
};

const TableStyles = () => (
  <style>{`
#mui-rte-container {
  margin: 0;
}
#mui-rte-editor {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
}
.questionEdit--disable .icon--disable path {
  fill: #b71c1c;
}
.questionEdit--disable {
  border: none;
}
.question-label span > * {
  display: inline-block !important;
}
.question-label img {
  width: auto !important;
  height: 24px !important;
}
.questionEdit img {
  cursor: pointer;
  transition: 0.4s ease;
}
.questionEdit path {
  transition: 0.4s ease;
}
.questionEdit .btn--deleteAnswer:hover path {
  fill: #b71c1c;
}
.questionEdit .btn--correctAnswer {

  .richEditor {
    border: 1px solid #7cb342;
  }

  .markCorrect path {
    fill: #7cb342;
    stroke: #7cb342;
  }
}
.question--disable::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  width: 94%;
  height: 1px;
  background: black;
  z-index: 2;
}
.questionsTable .MuiTableCell-sizeMedium {
  padding: 5px 10px;
}
.questionsTable .MuiTableCell-sizeMedium:first-child {
  padding: 5px 15px;
}

.richEditor img {
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
.richEditor--disabled {
  background-color: rgb(225 225 225 / 50%) !important;
}
.MJXc-display {
  display: inline !important;
}

.questionEdit-scroll {
  max-height: 80vh;
  padding-bottom: 30px;
  padding-right: 10px;
  overflow-y: auto;
}
.questionEdit-scroll::-webkit-scrollbar {
  width: 10px;               /* ширина scrollbar */
}
.questionEdit-scroll::-webkit-scrollbar-track {
  background: #e1f5fe;        /* цвет дорожки */
}
.questionEdit-scroll::-webkit-scrollbar-thumb {
  background-color: #2065D1;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 3px solid #e1f5fe;  /* padding вокруг плашки */
}
.questionEdit iframe,
.test-preview iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16 / 9;
}
`}</style>
);
