import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export default function TestToggle(props) {
  const {meta} = useContext(AuthContext);

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      {...props}
    >
      <ToggleButton value="ent">ЕНТ</ToggleButton>
      <ToggleButton value="nuet" disabled={meta?.role?.slug !== 'superadmin'}>НУЕТ</ToggleButton>
    </ToggleButtonGroup>
  );
}
