import { MenuItem, Select } from '@mui/material';

const types = [
  {
    value: 'dot:Point',
    label: 'Точка',
  },
  {
    value: 'dashed:LineString',
    label: 'Пунктирная линия',
  },
  {
    value: 'polygon:Polygon',
    label: 'Полигон',
  },
  {
    value: 'symbol:Point',
    label: 'Название',
  },
  {
    value: 'line:LineString',
    label: 'Линия',
  },
  {
    value: 'icon:LineString',
    label: 'Иконка',
  },
];

const TypeSelect = ({ value, onChange }) => (
  <Select sx={{ background: 'white' }} value={`${value.properties.type}:${value.geometry.type}`} onChange={(e) => onChange(e.target.value)}>
    {types.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </Select>
);

export default TypeSelect;
