import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

QuestionListToolbar.propTypes = {
  handleAddQuestion: PropTypes.func,
  handleAddContext: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handlerRefresh: PropTypes.func,
  setOrder: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  order: PropTypes.string,
  test: PropTypes.string,
};

export default function QuestionListToolbar({
  handlerRefresh,
  filterName,
  onFilterName,
  handleAddQuestion,
  handleAddContext,
  loading,
  error,
  order,
  setOrder,
  test,
}) {
  return (
    <RootStyle>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton onClick={setOrder}>
          {order === 'asc' && <Iconify icon="tabler:sort-ascending-numbers" width={24} height={24} />}
          {order === 'desc' && <Iconify icon="tabler:sort-descending-numbers" width={24} height={24} />}
        </IconButton>
        <IconButton sx={{ height: 'fit-content' }} onClick={handlerRefresh}>
          <Iconify icon="ep:refresh" width={24} height={24} />
        </IconButton>
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Поиск вопросов"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        {(test === 'ent' || test === 'schools') && (
          <LoadingButton
            loading={loading}
            disabled={error}
            variant="contained"
            color="info"
            size="large"
            onClick={handleAddContext}
          >
            Добавить контекст
          </LoadingButton>
        )}
        <LoadingButton loading={loading} disabled={error} variant="contained" size="large" onClick={handleAddQuestion}>
          Добавить вопрос
        </LoadingButton>
      </Stack>
    </RootStyle>
  );
}
