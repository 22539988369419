/* eslint-disable spaced-comment */
/* eslint-disable no-plusplus */
import { Box, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { ImageManager } from '../../components/imageManager';
import useGetApi from '../../hooks/useGetApi';
import EditMode from './EditMode';
import GeoLibrary from './GeoLibrary';
import MapEvents from './MapEvents';

const MapPreview = ({ height }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const popup = useRef(new maplibregl.Popup({ closeOnClick: false }));
  const [loading, geojson, mutateGeojson] = useGetApi('geojson', []);
  const [loadingEvents, events, mutateEvents] = useGetApi('geojson/events', []);

  const [activeEventId, setActiveEventId] = useState(null);

  const [activeGeo, setActiveGeo] = useState(null);
  const [showGeos, setShowGeos] = useState([]);

  const activeGeoHandle = (obj) => {
    setActiveGeo(obj);
    setIsAdd(false);

    const { coordinates, type } = obj.geometry;
    let center = coordinates;
    if (type === 'LineString') center = coordinates[0];
    if (type === 'Polygon') center = coordinates[0][0];

    map.current.flyTo({ center });
  };
  const cancelEditHandle = () => {
    setActiveGeo(null);
    setIsAdd(false);
  };

  const mutateChangeEvents = (event, type) => {
    if (type === 'edit') mutateEvents(events.map((x) => (x.id === event.id ? event : x)));
    if (type === 'add') mutateEvents([...events, event]);
  };

  const mutateChangeGeojson = (geo, type) => {
    if (type === 'edit') mutateGeojson(geojson.map((x) => (x.properties.id === geo.properties.id ? geo : x)));
    if (type === 'add') mutateGeojson([...geojson, geo]);
  };

  // Edit Mode
  const [isAdd, setIsAdd] = useState(false);
  const enableAddHandler = () => {
    setActiveGeo(null);
    setIsAdd(true);
  };

  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: mapStyle,
      center: [101.93716398352655, 40.82744679742865],
      zoom: 3.5,
      // dragRotate: false,
      // touchZoomRotate: false,
      // scrollZoom: false,
    });

    map.current._canvasContainer.style.cursor = 'auto';

    map.current.on('load', () => {
      map.current.addSource('background', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-180, 90],
                [-180, -90],
                [180, -90],
                [180, 90],
                [-180, 90],
              ],
            ],
          },
          properties: {},
        },
      });
      map.current.addSource('source-edit', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        generateId: true,
        lineMetrics: true,
      });
      map.current.addSource('source', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        generateId: true,
      });

      mapInitLayers.forEach((layer) => {
        // if (layer.source === 'source-edit') layer.source = 'source';

        map.current.addLayer(layer);
      });

      setIsMapLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (activeGeo !== null || !isMapLoaded) return;

    const prevSource = { ...map.current.getSource('source-edit')._data };
    prevSource.features = showGeos;
    map.current.getSource('source-edit').setData(prevSource);
  }, [showGeos]);

  const scrollHandler = (e) => {
    const $events = document.querySelectorAll('[data-id]');

    for (let i = 0; i < $events.length; i++) {
      const $event = $events[i];
      const eventId = +$event.getAttribute('data-id');
      const { top, bottom } = $event.getBoundingClientRect();

      const halfHeight = height / 2;
      if (top - 160 < halfHeight && halfHeight < bottom) {
        setActiveEventId(eventId);
        return;
      }
    }
  };

  useEffect(() => {
    if (activeEventId == null || !isMapLoaded || activeGeo !== null) return;

    const prevSource = { ...map.current.getSource('source-edit')._data };
    prevSource.features = geojson.filter(
      (x) => x.properties.hash <= activeEventId && activeEventId <= x.properties.hash_end
    );
    console.log(prevSource.features);
    map.current.getSource('source-edit').setData(prevSource);

    const activeEvent = events.find((x) => x.id === activeEventId);
    if (!activeEvent) return;

    map.current.flyTo({
      center: activeEvent.center.split(',').map((x) => +x.trim()),
      zoom: activeEvent.zoom,
      bearing: 1,
      speed: +activeEvent.speed, // Скорость анимации (от 0 до 1)
      curve: 1.2, // Кривая анимации (от 1 до бесконечности)
    });
  }, [activeEventId, activeGeo]);

  return (
    <Box>
      <ImageManager />
      <GeoLibrary
        sx={{ marginBottom: 4 }}
        onChange={activeGeoHandle}
        geojson={geojson}
        activeObj={activeGeo}
        onAdd={enableAddHandler}
        onChangeShow={setShowGeos}
        show={showGeos}
      />

      <Stack direction="row" sx={{ position: 'relative', height, width: '100%' }}>
        <Box ref={mapContainer} className="map" sx={{ width: '100%', height: '100%' }}>
          {(activeGeo !== null || isAdd) && (
            <EditMode
              sx={{ position: 'absolute', zIndex: 2 }}
              map={map}
              isAdd={isAdd}
              popup={popup}
              activeGeo={activeGeo}
              onClose={cancelEditHandle}
              events={events}
              mutateChangeGeojson={mutateChangeGeojson}
            />
          )}
        </Box>

        {isMapLoaded && (
          <MapEvents
            mutateChangeEvents={mutateChangeEvents}
            events={events}
            onScroll={scrollHandler}
            activeEventId={activeEventId}
            height={height}
            map={map}
          />
        )}
      </Stack>
    </Box>
  );
};

export default MapPreview;

const mapInitLayers = [
  {
    id: 'background-layer',
    type: 'fill',
    source: 'background',
    paint: {
      'fill-color': 'rgba(0, 0, 0, 0.3)',
    },
  },

  {
    id: 'dots',
    type: 'circle',
    source: 'source-edit',
    paint: {
      'circle-stroke-color': ['get', 'color'],
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 0.1,
      'circle-color': ['get', 'color'],
      'circle-radius': {
        base: 5,
        stops: [
          [2, 2],
          [4, 4],
        ],
      },
    },
    filter: ['==', 'type', 'dot'],
  },

  {
    id: 'dashed',
    type: 'line',
    source: 'source-edit',
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 2,
      'line-opacity': 0.8,
      'line-dasharray': [2, 2],
    },
    filter: ['==', 'type', 'dashed'],
  },

  {
    id: 'polygon',
    type: 'fill',
    source: 'source-edit',
    paint: {
      'fill-color': ['get', 'color'],
      'fill-outline-color': ['get', 'color_outline'],

      'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.12, 0.05],
    },
    filter: ['==', 'type', 'polygon'],
  },

  {
    id: 'symbol',
    type: 'symbol',
    source: 'source-edit',
    layout: {
      'text-field': ['get', 'title'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.2,
      'text-justify': 'auto',
      'text-max-width': 8,
      'text-size': ['to-number', ['get', 'text_size'], 15],
      'text-rotate': ['to-number', ['get', 'text_rotate'], 0],
    },
    paint: {
      'text-color': ['get', 'color'],
      'text-halo-width': 1,
    },
    filter: ['==', 'type', 'symbol'],
  },

  {
    id: 'lines',
    type: 'line',
    source: 'source-edit',
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 1,
      'line-opacity': 0.8,
    },
    filter: ['==', 'type', 'line'],
  },

  {
    id: 'icon',
    type: 'line',
    source: 'source-edit',
    paint: {
      'line-color': 'black',
      'line-width': 1,
      'line-opacity': 0.8,
      'line-dasharray': [2, 2],
    },
    filter: ['==', 'type', 'icon'],
  },
];

const mapStyle = {
  version: 8,
  glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
  sources: {
    osm: {
      type: 'raster',
      tiles: ['https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=zAuOcOjUPxx3HebSBAXD'],
      tileSize: 256,
    },
  },
  layers: [
    {
      id: 'osm',
      type: 'raster',
      source: 'osm',
    },
    {
      id: 'overlay',
      type: 'background',
      paint: {
        'background-color': '#03103E',
        'background-opacity': 0.7,
      },
    },
  ],
};
