import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
  error: PropTypes.bool,
  empty: PropTypes.bool,
};

export default function SearchNotFound({ searchQuery = '', error, empty, ...other }) {
  return (
    <Paper {...other} sx={{paddingY: 2, boxShadow: 'none'}}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {!error && empty && searchQuery && 'Вопросы не найдены'}
        {error && 'Не удалось загрузить вопросы'}
        {!error && empty && !searchQuery  && 'Вопросов пока нету 🙃'}
      </Typography>
      {!error && empty && searchQuery && (
        <Typography variant="body2" align="center">
          По запросу <strong>&quot;{searchQuery}&quot;</strong> ничего не нашлось.&nbsp; Попробуйте поискать по другим
          словам.
        </Typography>
      )}
      {error && (
        <Typography variant="body2" align="center">
          Попробуйте перезагрузить страницу или обратиться к администратору.
        </Typography>
      )}
      {!error && empty && !searchQuery && (
        <Typography variant="body2" align="center">
          Их можно добавить с помощью синей кнопки сверху.
        </Typography>
      )}
    </Paper>
  );
}
