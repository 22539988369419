/* eslint-disable react/no-danger */
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Fab, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Iconify from '../../components/Iconify';
import { useApi } from '../../hooks/useApi';
import { useNotify } from '../../hooks/useNotify';
import palette from '../../theme/palette';
import SoundSelect from './Input/SoundSelect';

const MapEvents = ({ onScroll, activeEventId, height, events, map, mutateChangeEvents }) => {
  const [activeEventIdId, setEditEventId] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const listRef = useRef(null);
  const { notify } = useNotify();
  const [loading, api] = useApi();

  const cancelEditHandle = () => {
    setEditEventId(null);
  };

  useEffect(() => {
    if (!isAdd) return;
    setTimeout(() => {
      scrollToBottom();
    }, 1);
  }, [isAdd]);

  const scrollToBottom = () => {
    if (listRef.current) {
      const listElement = listRef.current;
      console.log(listElement.scrollHeight);
      listElement.scrollTo({
        top: listElement.scrollHeight - height * 1.5,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const copyEventHandler = (id) => {
    api(`geojson/events/${id}/copy`, {
      success: (event) => {
        mutateChangeEvents(event, 'add');
        notify('Событие успешно скопировано', 'success');
      },
      error: () => {
        notify('Ошибка при копировании', 'error');
      },
    });
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        width: 600,
        paddingX: 2,
        flexShrink: 0,
        overflowY: 'auto',
      }}
      ref={listRef}
      onScroll={onScroll}
    >
      <Box
        sx={{
          width: '100%',
          height,
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" color="text.secondary">
          Прокрутите ниже
        </Typography>
      </Box>
      {events.map((event, i) => {
        if (activeEventIdId === event.id)
          return (
            <EventEditItem
              mutateChangeEvents={mutateChangeEvents}
              key={event.id}
              map={map}
              onCancel={cancelEditHandle}
              event={event}
            />
          );

        return (
          <Box
            key={event.id}
            data-id={event.id}
            sx={{ position: 'relative', '&:hover .event-btn': { opacity: 1, pointerEvents: 'all' } }}
            paddingBottom={25}
          >
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={1}>
              {i === events.length - 1 && (
                <Tooltip title="Скопировать с GeoJson">
                  <IconButton
                    onClick={() => copyEventHandler(event.id)}
                    sx={{ opacity: 0, pointerEvents: 'none' }}
                    className="event-btn"
                  >
                    <Iconify icon="solar:copy-outline" />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={() => setEditEventId(event.id)}
                sx={{ opacity: 0, pointerEvents: 'none' }}
                className="event-btn"
              >
                <Iconify icon="tabler:edit" />
              </IconButton>
              <Typography color={activeEventId === event.id ? 'primary' : 'black'} variant="p" textAlign="right">
                #{event.id}
              </Typography>
              <Typography
                maxWidth={200}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace={'nowrap'}
                color={activeEventId === event.id ? 'primary' : 'black'}
                variant="h4"
                textAlign="right"
              >
                {event.title}
              </Typography>
            </Stack>
            <Typography variant="h4" textAlign="right">
              {event.subtitle}
            </Typography>
            <TextField fullWidth multiline disabled defaultValue={event.description} />
          </Box>
        );
      })}
      {isAdd ? (
        <EventEditItem mutateChangeEvents={mutateChangeEvents} map={map} isAdd onCancel={() => setIsAdd(false)} />
      ) : (
        <AddButton onClick={() => setIsAdd(true)} />
      )}
      <Box
        sx={{
          width: '100%',
          height: height / 2,
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </Stack>
  );
};

const AddButton = ({ onClick }) => (
  <Stack direction="row" alignItems="center">
    <Divider sx={{ flex: 1, borderColor: palette.primary.light, borderWidth: 1 }} />
    <Fab onClick={onClick} size="small" color="primary">
      <Iconify icon="ic:round-plus" size={20} />
    </Fab>
    <Divider sx={{ flex: 1, borderColor: palette.primary.light, borderWidth: 1 }} />
  </Stack>
);

const EventEditItem = ({ event, onCancel, map, isAdd = false, mutateChangeEvents }) => {
  const [eventEdit, setEventEdit] = useState(
    event || {
      id: 0,
      type: 'history',
      title: '',
      subtitle: '',
      description: '',
      center: map.current.getCenter().toArray().join(','),
      zoom: map.current.getZoom(),
      speed: 0.9,
      sound: null,
    }
  );
  const [loading, api] = useApi();
  const { notify } = useNotify();

  useEffect(() => {
    // if (eventEdit.zoom) map.current.setZoom(eventEdit.zoom);
    // if (eventEdit.center) map.current.setCenter(eventEdit.center.split(',').map(x => +x.trim()));
  }, [eventEdit]);

  const onChangeHandle = (e) => {
    setEventEdit({ ...eventEdit, [e.target.name]: e.target.value });
  };

  const centerChangeHandler = (e) => {
    const center = eventEdit.center.split(',') || [0, 0];
    if (e.target.name === 'lng') center[0] = e.target.value;
    else center[1] = e.target.value;
    setEventEdit({ ...eventEdit, center: center.join(',') });
  };

  const setZoomFromMap = () => {
    setEventEdit({ ...eventEdit, zoom: map.current.getZoom() });
  };
  const setCenterFromMap = () => {
    setEventEdit({ ...eventEdit, center: map.current.getCenter().toArray().join(',') });
  };

  const saveHandler = () => {
    const { id, type, title, subtitle, description } = eventEdit;
    // if (!type || !title || !subtitle || !description) return notify('Заполните обязательные поля', 'error');

    api(`geojson/events/${id}`, {
      method: 'PUT',
      body: formatEventJSON(eventEdit),
      success: () => {
        notify('Событие сохранено', 'success');
        mutateChangeEvents(event, 'edit');
        onCancel();
      },
      error: () => {
        notify('Ошибка при сохранении', 'error');
      },
    });
  };

  const addHandler = () => {
    const { id, type, title, subtitle, description } = eventEdit;
    // if (!type || !title || !subtitle || !description) return notify('Заполните обязательные поля', 'error');

    api(`geojson/events`, {
      method: 'POST',
      body: formatEventJSON(eventEdit),
      success: (event) => {
        notify('Событие добавлено', 'success');
        mutateChangeEvents(event, 'add');
        onCancel();
      },
      error: () => {
        notify('Ошибка при добавлении', 'error');
      },
    });
  };

  return (
    <Stack data-id={eventEdit.id} paddingBottom={25}>
      <Typography marginBottom={1} variant="h6">
        {isAdd ? 'Добавление события' : 'Редактирование события'}
      </Typography>

      <TextField
        label="Заголовок"
        value={eventEdit.title}
        onChange={(e) => setEventEdit({ ...eventEdit, title: e.target.value })}
      />
      <TextField
        sx={{ marginTop: 2 }}
        label="Подзаголовок"
        value={eventEdit.subtitle}
        onChange={(e) => setEventEdit({ ...eventEdit, subtitle: e.target.value })}
      />
      <Typography marginTop={2} color="text.secondary">
        Описание
      </Typography>
      <TextField
        value={eventEdit.description}
        multiline
        maxRows={15}
        onChange={(e) => setEventEdit({ ...eventEdit, description: e.target.value })}
      />

      <Divider sx={{ marginY: 2 }} />

      <Stack spacing={1}>
        <Typography variant="h6" color="text.primary">
          Свойства
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip disableInteractive title="Взять значение с карты">
            <IconButton onClick={setZoomFromMap} sx={{ flexShrink: 0 }}>
              <Iconify icon="solar:map-linear" />
            </IconButton>
          </Tooltip>
          <TextField value={eventEdit.zoom} type="number" label="Зум" name="zoom" onChange={onChangeHandle} />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip disableInteractive title="Взять значение с карты">
            <IconButton onClick={setCenterFromMap} sx={{ flexShrink: 0 }}>
              <Iconify icon="solar:map-linear" />
            </IconButton>
          </Tooltip>
          <TextField value={eventEdit.center.split(',')[0]} label="Lng" name="lng" onChange={centerChangeHandler} />
          <TextField value={eventEdit.center.split(',')[1]} label="Lat" name="lat" onChange={centerChangeHandler} />
        </Stack>
        <TextField value={eventEdit.speed} type="number" label="Скорость" name="speed" onChange={onChangeHandle} />
        <SoundSelect label="Звук" value={eventEdit} name="sound" onChange={onChangeHandle} />
      </Stack>

      <Divider sx={{ marginY: 2 }} />

      <Stack direction="row">
        {!isAdd && (
          <LoadingButton variant="contained" fullWidth loading={loading} onClick={saveHandler}>
            <Typography fontWeight={500}>Сохранить</Typography>
          </LoadingButton>
        )}
        {isAdd && (
          <LoadingButton variant="contained" fullWidth loading={loading} onClick={addHandler}>
            <Typography fontWeight={500}>Добавить</Typography>
          </LoadingButton>
        )}

        <Button onClick={onCancel} color="info">
          <Iconify icon="iconoir:cancel" size={24} />
        </Button>
      </Stack>
    </Stack>
  );
};

const formatEventJSON = (event) => {
  const { id, type, title, subtitle, description } = event;
  const properties = { ...event };
  delete properties.id;
  delete properties.type;
  delete properties.title;
  delete properties.subtitle;
  delete properties.description;

  return {
    id,
    type,
    title,
    subtitle,
    description,
    properties,
  };
};

export default MapEvents;
