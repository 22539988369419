import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
// layouts
import DashboardLayout from './layouts/dashboard';
import Activity from './pages/Activity';
import DashboardApp from './pages/DashboardApp';
import Login from './pages/Login';
import Media from './pages/Media';

import MapEditor from './pages/MapEditor/MapEditor';
import Questions from './pages/Questions';
import Lesson from './pages/Subject/Lesson';
import Subjects from './pages/Subject/Subjects';
import Themes from './pages/Subject/Themes';
import Users from './pages/Users';
import EasyTests from './pages/EasyTests';

// ----------------------------------------------------------------------

export default function Router() {
  const auth = useContext(AuthContext);

  if (auth.isAuth) {
    return (
      <DashboardLayout>
        <Routes>
          <Route path="dashboard" element={<DashboardApp />} />
          <Route path="questions" element={<Questions />} />
          <Route path="users" element={<Users />} />
          <Route path="easy-tests" element={<EasyTests />} />
          <Route path="activity" element={<Activity />} />
          <Route path="subjects" element={<Subjects />} />
          <Route path="subjects/:subjectSlug" element={<Themes />} />
          <Route path="subjects/:subjectSlug/:themeId" element={<Lesson />} />
          <Route path="media" element={<Media />} />
          <Route path="map-editor" element={<MapEditor />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </DashboardLayout>
    );
  }

  return (
    <Routes>
      <Route path="/auth" element={<Login />} />
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
}
