import { Container, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import { SubjectsEditor } from '../../components/subjects';
import TestToggle from '../../components/TestToggle';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';

export default function Subjects() {
  const [test, setTest] = useState('ent');
  const addNotify = useContext(NotifyContext);

  // ========= SUBJECTS =========
  const [subjects, setSubjects] = useState([]);
  const [loadingSubjects, fetchSubjects] = useAjax((data, error) => {
    if (error) {
      addNotify(data.msg || 'Ошибка', 'error');
      console.log(data.error);
      return;
    }

    console.log(data);
    setSubjects(data);
  });

  useEffect(() => {
    fetchSubjects('subjects/themes');
  }, []);

  return (
    <Page title="Subjects">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center" divider={<span style={{ opacity: 0.5 }}>—</span>}>
            <Typography variant="h4">Предметы</Typography>

            <TestToggle
              value={test}
              onChange={(e, newState) => {
                if (newState) {
                  setTest(newState);
                }
              }}
            />
          </Stack>
        </Stack>
        {loadingSubjects ? (
          <LinearProgress />
        ) : (
          <SubjectsEditor
            refreshSubjects={() => fetchSubjects('subjects/themes')}
            subjects={subjects.filter((s) => s.test === test)}
          />
        )}
      </Container>
    </Page>
  );
}
