import { Box, IconButton, Slider, Tooltip, Typography } from '@mui/material';
import Iconify from '../Iconify';

const ContextRange = ({ onChange, value }) => (
  <Box>
    <Typography>
      Номера вопросов{' '}
      <Tooltip title="Номера вопросов, для которых нужен этот контекст">
        <IconButton>
          <Iconify icon="clarity:help-solid" />
        </IconButton>
      </Tooltip>
    </Typography>
    <Slider
      marks={marks}
      style={{ width: 400 }}
      value={value}
      onChange={onChange}
      valueLabelDisplay="auto"
      min={1}
      max={35}
    />
  </Box>
);

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
];

export default ContextRange;
