import { useContext, useEffect, useState } from 'react';
// material
import {
  Container,
  Typography,
  ImageList,
  ImageListItem,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  Box,
} from '@mui/material';
// components
import Page from '../components/Page';
import ImageManager from '../components/imageManager/ImageManager';
import { useAjax } from '../hooks/useAjax';
import { NotifyContext } from '../contexts/NotifyContext';
import useGetApi from '../hooks/useGetApi';

export default function Media() {
  // ================ IMAGES
  const [loadingImages, images] = useGetApi('uploads/photos?limit=50', [])

  // ================ VIDEOS
  const [videos, setVideos] = useState([]);
  const [loadingVideos, getVideos] = useAjax((data, error) => {
    if (error) {
      addNotify('Ошибка при загрузке видео', 'error');
      console.warn('Fetch error getVideos', data.error);
      return;
    }
    setVideos(data.data);
    console.log(data.data);
  }, false);

  // =============== LOADING

  useEffect(() => {
    getVideos('https://api.vimeo.com/me/videos?per_page=25', 'GET', null, {
      Authorization: 'Bearer 03c2d99add48b701818c08d2c9673d2c',
    });
  }, []);

  const addNotify = useContext(NotifyContext);

  const [alignment, setAlignment] = useState('images');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <Page title="Media">
      <ImageManager />
      <Container maxWidth="xl">
        <Stack direction="row" spacing={3} alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography variant="h4" gutterBottom>
            Медиа
          </Typography>
          <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
            <ToggleButton value="images">Изображения</ToggleButton>
            <ToggleButton value="videos">Видео</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Box sx={{ display: alignment === 'images' ? 'block' : 'none' }}>
          {loadingImages && <LinearProgress />}
          <ImageList cols={8} gap={20} rowHeight={150} sx={{ overflow: 'visible' }}>
            {images.map((img, i) => (
              <ImageListItem
                key={i}
                sx={{ borderRadius: '8px', boxShadow: 3, overflow: 'hidden', cursor: 'pointer' }}
                onClick={() => {
                  sessionStorage.setItem('copyMediaSrc', `<img src="https://jaryq-academy.kz/uploads/${img}" />`);
                  addNotify(`Картинка скопирована`);
                }}
                className="imageManager__item"
              >
                <img src={`https://jaryq-academy.kz/uploads/${img}`} alt={img} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>

        <Box sx={{ display: alignment === 'videos' ? 'block' : 'none' }}>
          {loadingVideos && <LinearProgress />}
          <ImageList cols={8} gap={20} rowHeight={150} sx={{ overflow: 'visible' }}>
            {videos.map((video, index) => (
              <ImageListItem
                key={index}
                className="imageManager__item"
                onClick={() => {
                  sessionStorage.setItem(
                    'copyMediaSrc',
                    video?.embed?.html.replace('allowfullscreen', 'allowfullscreen="true"')
                  );
                  addNotify(`Видео ${video?.name} скопировано`);
                }}
              >
                <img
                  src={video?.pictures?.base_link}
                  srcSet={video?.pictures?.base_link}
                  alt={video?.name}
                  loading="lazy"
                  style={{
                    height: '90px',
                    objectFit: 'contain',
                    border: '1px solid #eeeeee',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                />
                <div style={{width: 169}} className="imageManager__item-name">{video?.name}</div>
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Container>
    </Page>
  );
}
