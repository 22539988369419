import { Box, IconButton, styled, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import Iconify from '../Iconify';
import ThemesModal from './ThemesModal';

export default function ThemesItem({ theme, ordering, refreshThemes }) {
  const [openEdit, setOpenEdit] = useState(false);

  const [editTheme, setEditTheme] = useState(theme);

  const addNotify = useContext(NotifyContext);
  const [loadingUpdate, fetchUpdateTheme] = useAjax((data, error) => {
    setOpenEdit(false);
    if (error) {
      console.warn(data.error);
      addNotify(data.msg || 'Ошибка при обновлении', 'error');
      return;
    }

    addNotify(`Тема №${data.id} успешно обновлена`, 'success');
    refreshThemes();
  });

  const handleOpenEdit = () => {
    setEditTheme(theme);
    setOpenEdit(true);
  };

  const handleEditTheme = () => {
    fetchUpdateTheme(`themes/${theme.id}`, 'PUT', {
      label: editTheme.label,
      label_kz: editTheme.label_kz,
      props: editTheme.props,
      slug: editTheme.slug,
      topic: editTheme.topic,
      topic_kz: editTheme.topic_kz,
    });
  };

  return (
    <>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          ...rowHover,
        }}
      >
        <TableCell>{theme.id}</TableCell>
        <TableCell>
          {theme.label_kz && (theme.topic ? !!theme.topic_kz : true) && <KZ>KZ</KZ>}
          {theme.topic && <Topic>{theme.topic}</Topic>}
          {theme.label}
        </TableCell>
        <TableCell align="center">{theme.props || '-'}</TableCell>
        <TableCell align="right">
          <Tooltip disableInteractive title="Редактирование">
            <IconButton onClick={handleOpenEdit}>
              <Iconify icon="eva:edit-outline" width={24} height={24} />
            </IconButton>
          </Tooltip>
          <Link to={`./${theme.id}`} style={{ pointerEvents: !theme.props.includes('noLesson') ? 'all' : 'none' }}>
            <Tooltip disableInteractive title={theme.haveLesson ? 'Урок' : 'Нет урока'} sx={{ marginLeft: 2 }}>
              <IconButton disabled={theme.props.includes('noLesson')}>
                {theme.haveLesson && <LessonIndicator />}
                <Iconify icon="carbon:document" width={24} height={24} />
              </IconButton>
            </Tooltip>
          </Link>
          <Link to={`./${theme.id}?kz`} style={{ pointerEvents: !theme.props.includes('noLesson') ? 'all' : 'none' }}>
            <Tooltip disableInteractive title={theme.haveLesson ? 'Урок' : 'Нет урока'} sx={{ marginLeft: 2 }}>
              <IconButton disabled={theme.props.includes('noLesson')}>
                {theme.haveLesson && <LessonIndicator />}
                <Iconify icon="carbon:document" width={24} height={24} />
                <Typography variant="h6" style={{ fontSize: 14 }}>
                  KZ
                </Typography>
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>

      <ThemesModal
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        theme={editTheme}
        setTheme={setEditTheme}
        isAdd={false}
        refreshThemes={() => {}}
        loading={loadingUpdate}
        btnHandler={handleEditTheme}
      />
    </>
  );
}

const rowHover = {
  transition: '0.4s ease',
  '&:hover': {
    backgroundColor: '#2065d10a',
  },
};

const KZ = styled(Box)(() => ({
  display: 'inline-block',
  marginRight: 10,
  padding: '0 10px',
  borderRadius: '20px',
  backgroundColor: '#f0c636',
  color: 'white',
}));

const Topic = styled(Box)(() => ({
  display: 'inline-block',
  marginRight: 10,
  padding: '0 10px',
  borderRadius: '20px',
  backgroundColor: '#2065D1',
  color: 'white',
}));

const LessonIndicator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 5,
  right: 5,
  width: 8,
  height: 8,
  backgroundColor: '#2065D1',
  borderRadius: 15,
}));
