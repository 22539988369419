/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';

export default function QuestionHead({ id, subject, indicator, themeID, themeLabel, probeNumber = false, question, disable, setIsExpand, isExpand }) {
  return (
    <TableRow hover tabIndex={-1} className={disable ? 'question--disable' : ''} sx={{ position: 'relative' }}>
      <TableCell align="left">{id}</TableCell>
      <TableCell
        sx={{ background: subject?.color, color: 'white', textAlign: 'center', whiteSpace: 'nowrap' }}
        align="left"
      >
        {subject?.label}
      </TableCell>
      <TableCell sx={{ textAlign: 'center', borderRight: indicator ? `2px solid ${indicator}` : 'none' }} align="left">
        <Tooltip disableInteractive title={themeLabel}>
          <Box>{themeID === -1 ? 'Нет темы' : themeID}</Box>
        </Tooltip>
      </TableCell>
      <TableCell sx={questionStyle} className="question-label" align="left">
        {probeNumber && <span style={{ marginRight: '5px', fontWeight: '500' }}>№{probeNumber} -</span>}
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => setIsExpand(!isExpand)}>
          <Iconify
            icon="akar-icons:chevron-down"
            style={{ transform: isExpand ? 'rotate(180deg)' : '', transition: '0.4s ease' }}
            width={24}
            height={24}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const questionStyle = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '55vw',
  overflow: 'hidden',
  borderBottom: '1px solid rgba(241, 243, 244, 1)',
};
