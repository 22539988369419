import { Card, colors, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import useGetApi from '../../hooks/useGetApi';
import Iconify from '../Iconify';

const rules = {
  profile: {
    ques: 35,
  },
  'math-lit': {
    ques: 15,
  },
  'read-lit': {
    ques: 15,
  },
  'hist-kz': {
    ques: 20,
  },
};

const QuestionsStatus = () => {
  const [loading, subjects] = useGetApi('subjects?test=ent', []);
  const [loadingProbes, probeData] = useGetApi('ques/probes', {
    probes: {
      kz: {},
      ru: {},
    },
    list: [],
  });
  const [subject, setSubject] = useState({});
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    document.querySelectorAll('.apexcharts-yaxis-texts-g tspan').forEach((x) => {
      x.style.cursor = 'pointer';
    });
    setSubject({ ...subjects[0], lang: 'ru' } || {});
  }, []);

  const getStatus = (ques) => {
    const success = <Iconify sx={{ marginLeft: 1 }} icon="ep:success-filled" size={24} color={colors.green[300]} />
    const failed = <Iconify sx={{ marginLeft: 1 }} icon="gridicons:cross-circle" size={24} color={colors.red[300]} />

    if (subject.type === 'profile') return rules.profile.ques === ques ? success : failed
    return rules[subject.slug].ques === ques ? success : failed
  }

  console.log(probeData.list);

  return (
    <Card sx={{ padding: 2, boxShadow: 5 }}>
      <Typography marginBottom={1} variant="h5" textAlign="center">
        Статус пробников
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Chart
            options={{
              chart: {
                id: 'apexchart-example',
                stacked: true,
                events: {
                  dataPointSelection(e) {
                    const [i, lang] = [e.target.getAttribute('j'), e.target.getAttribute('index')];
                    setSubject({ ...subjects[i], lang: ['ru', 'kz'][lang] });
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900,
                      },
                    },
                  },
                },
              },
              xaxis: {
                categories: subjects.map((x) => x.label),
              },
            }}
            series={[
              {
                name: 'ru',
                data: subjects.map((x) => probeData.probes.ru[x.id] || 0),
              },
              {
                name: 'kz',
                data: subjects.map((x) => probeData.probes.kz[x.id] || 0),
              },
            ]}
            type="bar"
            height={600}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack
            sx={{
              borderRadius: 2,
              boxShadow: 5,
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                backgroundImage: subject.color,
                textAlign: 'center',
                padding: '5px 0',
                color: 'white',
              }}
            >
              {subject.label} {subject?.lang?.toUpperCase()}
            </Typography>
            <Stack
              spacing={2}
              sx={{
                padding: 2,
                overflow: 'auto',
                height: 600,
              }}
            >
              {probeData.list
                .filter((x) => x.subjectID === subject.id && x.lang === subject.lang)
                .map((probe, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => {
                      localStorage.setItem('active-subject', probe.subjectID)
                      localStorage.setItem('active-probe', probe.probe)
                      localStorage.setItem('lang-questions', probe.lang)
                      window.open('/questions')
                    }}
                    sx={{
                      padding: 1,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography display="flex" alignItems="center">
                      Пробник {probe.probe} {getStatus(probe.count.ques)}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Tooltip title="Контекст" disableInteractive>
                        <Stack direction="row" alignItems="center">
                          <Typography fontWeight={600} marginRight={0.5} fontSize={18} color={colors.blue[600]}>
                            {probe.count.ctxs}
                          </Typography>
                          <Iconify icon="material-symbols:text-snippet" size={24} color={colors.blue[600]} />
                        </Stack>
                      </Tooltip>
                      <Tooltip title="Вопросы" disableInteractive>
                        <Stack direction="row" alignItems="center">
                          <Typography fontWeight={600} marginRight={0.5} fontSize={18} color={colors.blue[600]}>
                            {probe.count.ques}
                          </Typography>
                          <Iconify icon="mdi:question-mark-box" size={24} color={colors.blue[600]} />
                        </Stack>
                      </Tooltip>
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default QuestionsStatus;
