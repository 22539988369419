const colors = [
    '#F44336',
    '#0288D1',
    '#00838F',
    '#311B92',
    '#B388FF',
    '#BE4DFF',
    '#4419A0',
    '#00A8EA',
    '#2942CA',
    '#FFEA00',
    '#DB9732',
    '#788BF3',
    '#3ABE57',
    '#B85CC0',
    '#E0377E',
    '#37F7C7',
    '#B026FF',
    '#D9FF2F',
    '#FD2EB8',
    '#FF6D00',
  ]

export const getColor = (id) => id ? colors[id % colors.length] : null;
