/* eslint-disable react/prop-types */
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

export default function NumberInput({ label, value, onChange, disabled }) {
  return (
    <TextField
      label={label}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        const newValue = +e.target.value ? +e.target.value : 0;
        onChange(newValue);
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">№</InputAdornment>,
      }}
    />
  );
}
