import { Button, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import Iconify from '../Iconify';
import SubjectsModal from './SubjectsModal';

SubjectsEditor.propTypes = {
  subjects: PropTypes.array,
};

export default function SubjectsEditor({ subjects, refreshSubjects }) {
  const [editSubject, setEditSubject] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const handleEditSubject = (subj) => {
    setEditSubject(true);
    setActiveSubject(subj);
    setIsAdd(false);
  };
  const handleAddSubject = () => {
    setEditSubject(true);
    setActiveSubject({
      test: '',
      slug: '',
      label: 'Новый предмет',
      color: 'black',
    });
    setIsAdd(true);
  };

  const [activeSubject, setActiveSubject] = useState({
    test: '',
    slug: '',
    label: '',
    color: '',
  });
  const addNotify = useContext(NotifyContext);
  const [loadingUpdate, fetchUpdateSubjects] = useAjax((data, error) => {
    setEditSubject(false);
    if (error) {
      console.warn(data.error);
      addNotify(data.msg || 'Ошибка при обновлении', 'error');
      return;
    }

    console.log(data);
    addNotify(`Предмет ${data.label} успешно ${isAdd ? 'создан' : 'обновлен'}`, 'success');
    refreshSubjects();
  });

  const updateSubject = () => {
    if (isAdd) {
      fetchUpdateSubjects('subjects', 'POST', activeSubject);
    } else {
      fetchUpdateSubjects('subjects', 'PUT', {
        id: activeSubject.id,
        label: activeSubject.label,
        color: activeSubject.color,
      });
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        {subjects.map((subj, index) => (
          <Grid
            item
            xs={1.3}
            key={index}
            sx={{
              position: 'relative',
              '&:hover': {
                '.MuiButton-sizeMedium': { opacity: 1 },
              },
            }}
          >
            <SubjectItem
              href={`/subjects/${subj.slug}?test=${subj.test}`}
              underline="none"
              sx={{ background: subj.color }}
            >
              <Typography variant="subtitle1" textAlign="center">
                {subj.label}
              </Typography>
            </SubjectItem>
            <EditIcon onClick={() => handleEditSubject(subj)} variant="contained">
              <Iconify icon="eva:edit-outline" color="black" width={24} height={24} />
            </EditIcon>
            <ThemesNumber variant="subtitle2" textAlign="center">
              {wordEnding(subj?.themes?.length)}
            </ThemesNumber>
          </Grid>
        ))}
        <Grid item xs={1.3}>
          <SubjectItem onClick={handleAddSubject}>
            <Button sx={{ width: '100%', height: '100%' }}>
              <Iconify icon="akar-icons:plus" width={48} height={48} />
            </Button>
          </SubjectItem>
        </Grid>
      </Grid>
      <SubjectsModal
        open={editSubject}
        handleClose={() => setEditSubject(false)}
        subject={activeSubject}
        setSubject={setActiveSubject}
        isAdd={isAdd}
        updateSubject={updateSubject}
        loadingUpdate={loadingUpdate}
      />
    </>
  );
}

const wordEnding = (n) => {
  const n_ = Math.abs(n) % 100;
  const n1 = n_ % 10;
  if (n_ > 10 && n_ < 20) return `${n} тем`;
  if (n1 > 1 && n1 < 5) return `${n} темы`;
  if (n1 === 1) return `${n} тема`;
  return `${n} тем`;
};

const SubjectItem = styled(Link)(({ theme }) => ({
  aspectRatio: '1/1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  cursor: 'pointer',
  transition: '0.4s ease',
  backgroundSize: '200% 100%',
  backgroundPosition: 'left',
  borderRadius: 8,
  boxShadow: theme.shadows[4],
  '&:hover': {
    backgroundPosition: 'right',
  },
}));

const EditIcon = styled(Button)(() => ({
  opacity: 0,
  position: 'absolute',
  top: 20,
  right: 10,
  zIndex: 5,
  backgroundColor: 'white',
  padding: '6px',
  minWidth: '0',
  transition: '0.4s ease',
  '&:hover': {
    backgroundColor: '#e3e3e3',
  },
}));

const ThemesNumber = styled(Typography)(() => ({
  position: 'absolute',
  bottom: 10,
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'white',
  opacity: 0.8,
  zIndex: 5,
}));
