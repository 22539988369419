/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useRef } from 'react';
import Iconify from '../../../components/Iconify';

const ImagePicker = ({ value, onChange, name }) => {
    const img = useRef()

    const loadFromBuffer = () => {
        const buffer = sessionStorage.getItem('copyMediaSrc');
        if (buffer) {
            onChange({
                target: {
                    name,
                    value: buffer.match(/<img\s+src="([^"]+)"/)[1] || ''
                }
            })
        }
    }

    return <Box>
    <Typography color="white">Изображение</Typography>
    <Stack direction='row' sx={{ position: 'relative' }}>
      <img
        ref={img}
        style={{ position: 'absolute', top: 8, left: 8, width: 40, height: 40, objectFit: 'cover', zIndex: 2 }}
        src={value.properties.image || 'https://jaryq-academy.kz/image-error.png'}
        alt={value.properties.image || 'https://jaryq-academy.kz/image-error.png'}
        onError={() => {
          img.current.src = 'https://jaryq-academy.kz/image-error.png';
        }}
      />
      <TextField
        name={name}
        sx={{ '& .MuiOutlinedInput-root': { background: 'white', paddingLeft: 5 } }}
        value={value.properties.image}
        onChange={onChange}
      />
      <Button onClick={loadFromBuffer} variant='contained'><Iconify size={24} icon='material-symbols:content-paste-go' /></Button>
    </Stack>
  </Box>
};

export default ImagePicker;
