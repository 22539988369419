/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import ThemesModal from './ThemesModal';

export default function ThemesAddButton({ subjectId, refreshThemes, themesLength }) {
  const [editTheme, setEditTheme] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
    setEditTheme({
      parent: subjectId,
      label: 'Новая тема',
      label_kz: 'Жаңа тақырып',
      topic: '',
      topic_kz: '',
      props: '',
      number: themesLength + 1,
    });
  };

  const addNotify = useContext(NotifyContext);
  const [loadingUpdate, fetchAddTheme] = useAjax((data, error) => {
    setOpen(false);
    if (error) {
      console.warn(data.error);
      addNotify(data.msg || 'Ошибка при добавлении', 'error');
      return;
    }

    addNotify(`Тема №${data.id} успешно добавлена`, 'success');
    refreshThemes();
  });

  const handleAddTheme = () => {
    fetchAddTheme(`themes`, 'POST', editTheme);
    console.log(editTheme);
  };

  return (
    <>
      <Button onClick={handleOpenModal} variant="contained">
        Добавить новую тему
      </Button>
      <ThemesModal
        open={open}
        handleClose={() => setOpen(false)}
        theme={editTheme}
        setTheme={setEditTheme}
        isAdd
        refreshThemes={() => {}}
        loading={loadingUpdate}
        btnHandler={handleAddTheme}
      />
    </>
  );
}
