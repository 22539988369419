// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider } from './contexts/AuthContext';
import { NotifyProvider } from './contexts/NotifyContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <NotifyProvider>
        <AuthProvider>
          <ScrollToTop />
          <Router />
        </AuthProvider>
      </NotifyProvider>
    </ThemeProvider>
  );
}
