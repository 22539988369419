// material
import {
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import useGetApi from '../hooks/useGetApi';
import UsersTable from './EasyTests/UsersTable';


export default function EasyTests() {


  return (
    <Page title="Media">
      <Container maxWidth="xl">
        <Stack direction="row" spacing={3} alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography variant="h4" gutterBottom>
            Jaryq Tests - Тестирование МОДО
          </Typography>
        </Stack>

        <UsersTable />
      </Container>
    </Page>
  );
}
