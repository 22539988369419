import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import Page from '../components/Page';
import CreateProbeLink from '../components/users/CreateProbeLink';
import UserEdit from '../components/users/UserEdit';
import UsersSearch from '../components/users/UsersSearch';
import { NotifyContext } from '../contexts/NotifyContext';
import useGetApi from '../hooks/useGetApi';

const rowsPerPage = 10;

export default function Users() {
  const addNotify = useContext(NotifyContext);
  const [loading, users] = useGetApi('user/all', []);

  // Search
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onSearchHandler = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const visibleRows = useMemo(() => users
      .filter(
        (u) =>
          `id:${u.id}`.includes(search) ||
          `name:${u.name}`.includes(search) ||
          `phone:${u.phone}`.includes(search) ||
          `email:${u.email}`.includes(search)
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [page, search, users]);

  // Edit user
  const [editUser, setEditUser] = useState(null);

  return (
    <Page title="Users">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginBottom: 2 }}
          alignItems="center"
          divider={<span style={{ opacity: 0.5 }}>—</span>}
        >
          <Typography variant="h4">Пользователи</Typography>
        </Stack>

        <CreateProbeLink />

        <Card sx={{ marginTop: 4 }}>
          <Stack sx={{ padding: 3 }}>
            <UsersSearch search={search} onSearch={onSearchHandler} />
          </Stack>

          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell>Имя</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Телефон</TableCell>
                <TableCell align="right">Город</TableCell>
                <TableCell align="right">Курс</TableCell>
                <TableCell align="right">Дата регистрации</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((user) => (
                <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">
                    <Button onClick={() => setEditUser(user)} fullWidth variant="outlined">
                      {user.id}
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.name} {user.surname}
                  </TableCell>
                  <TableCell align="right">{user.email}</TableCell>
                  <TableCell align="right">{user.phone}</TableCell>
                  <TableCell align="right">{user.city}</TableCell>
                  <TableCell align="right">{user?.course?.toUpperCase()}</TableCell>
                  <TableCell align="right">{format(new Date(user.created), 'dd-MM-yyyy HH:mm')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            count={users.length}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>

      <UserEdit user={editUser} onClose={() => setEditUser(null)} />
    </Page>
  );
}
