/* eslint-disable react/prop-types */
import { DatePicker, LocalizationProvider } from '@mui/lab';
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  IconButton,
  LinearProgress,
  Link,
  ListSubheader,
  MenuItem,
  Modal,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addMonths, addWeeks, formatDistance } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useContext, useEffect, useState } from 'react';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import { useApi } from '../../hooks/useApi';
import useGetApi from '../../hooks/useGetApi';
import Iconify from '../Iconify';

const subTypes = [
  {
    value: '',
    label: 'Нету',
  },
  {
    value: 'basic',
    label: 'Basic',
  },
  {
    value: 'gold',
    label: 'Gold',
  },
  {
    value: 'premium',
    label: 'Premium',
  },
];

const UserEdit = ({ user, onClose }) => {
  const [loadingCurators, curators] = useGetApi(`schools/supervisor/classes`, []);
  const [loading, tests] = useGetApi(user?.id ? `tests/?userId=${user.id}` : null, []);
  const [loadingApi, api] = useApi();

  // User Edit
  const [editUser, setEditUser] = useState(null);

  const addNotify = useContext(NotifyContext);
  const [loadingUserInfo, fetchUserInfo] = useAjax((data, err) => {
    if (err) return addNotify('Ошибка при получении пользователя', 'error');
    console.log('fetch', data);
    setEditUser(data);
  });

  const [loadingUserUpdate, fetchUserUpdate] = useAjax((data, err) => {
    if (err) return addNotify('Ошибка при сохранении пользователя', 'error');
    console.log('fetch', data);
    setEditUser({ ...editUser, sub: data });
    addNotify('Пользователь успешно обновлен', 'success');
  });

  useEffect(() => {
    if (user) {
      setEditUser(user);
      fetchUserInfo(`user/${user?.id}`);
    }
  }, [user]);

  const subTypeHandler = (e) => {
    const type = e.target.value;
    if (type === '') setEditUser({ ...editUser, sub: null });
    else setEditUser({ ...editUser, sub: { ...editUser.sub, slug: type } });
  };

  const subExpiredHandler = (date) => {
    setEditUser({ ...editUser, sub: { ...editUser.sub, expireDate: date.toISOString() } });
  };

  const subFreezeHandler = (e, v) => {
    if (v === 'active') fetchUserUpdate(`subs/unfreeze?userId=${user.id}`);
    if (v === 'freeze') fetchUserUpdate(`subs/freeze?userId=${user.id}`);
  };

  const curatorChangeHandler = (e) => {
    const curator = e.target.value;
    api(`user/${user.id}`, {
      method: 'PUT',
      body: { profile: { class: curator } },
      success: ({ profile }) => {
        setEditUser({ ...editUser, profile });
        addNotify('Куратор (класс) изменен', 'success');
      },
      error: (e) => {
        console.warn(e);
        addNotify('Ошибка при изменении куратора (класса)', 'error');
      },
    });
  };

  const saveHandler = () => {
    let newSub = null;
    if (editUser?.sub?.slug) {
      newSub = editUser.sub;
      if (!newSub.expireDate) newSub.expireDate = new Date().toISOString();
      if (!newSub.freeze) newSub.freeze = null;
    }

    fetchUserUpdate(`subs?userId=${user?.id}`, 'POST', newSub);
  };

  const addPeriod = (type) => {
    const date = editUser?.sub?.expireDate || new Date().toISOString();
    let newDate = addWeeks(new Date(date), 1);
    if (type === '+1month') newDate = addMonths(new Date(date), 1);
    if (type === '+3month') newDate = addMonths(new Date(date), 3);
    setEditUser({ ...editUser, sub: { ...editUser.sub, expireDate: newDate.toISOString() } });
  };

  return (
    <Modal disableEnforceFocus open={!!user}>
      <Container
        maxWidth="md"
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <IconButton onClick={onClose} sx={{ ...closeModalStyle }}>
          <Iconify icon="eva:close-fill" width={24} height={24} />
        </IconButton>

        <Card sx={{ padding: 3, background: '#e1f5fe', overflow: 'visible' }}>
          <Typography variant="h5">
            [{user?.id}] {user?.name}
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <TextField
              fullWidth
              select
              label="Куратор/Класс"
              value={editUser?.profile?.class || ''}
              onChange={curatorChangeHandler}
            >
              <MenuItem value="">Без куратора (класса)</MenuItem>
              {!!user?.class && <MenuItem value={user.class}>Текущий куратор (класс) - {user.class}</MenuItem>}
              <ListSubheader>Кураторы</ListSubheader>
              {curators.map((option) => (
                <MenuItem key={option.value} value={option.value} label={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {!!tests.length && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h4">Тесты</Typography>
              {loading ? (
                <LinearProgress />
              ) : (
                <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  {tests.map((test, i) => (
                    <Stack key={i}>
                      <Typography>
                        <Link target="_blank" href={`https://app.jaryq-academy.kz/test/${test.id}`}>
                          Тест {test.id}
                        </Link>{' '}
                        - {test.active ? 'Активен' : `Завершен [Баллов: ${test.finish_data?.score}]`}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              )}
            </Box>
          )}

          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
            <Typography variant="h4">Подписка</Typography>
            {editUser?.sub?.slug && (
              <ToggleButtonGroup
                color="primary"
                value={editUser?.sub?.freeze ? 'freeze' : 'active'}
                exclusive
                onChange={subFreezeHandler}
                aria-label="Platform"
              >
                <ToggleButton value="active">Активна</ToggleButton>
                <ToggleButton value="freeze">Заморожена</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Stack>

          <Stack direction="row" spacing={1} sx={{ marginTop: 3 }}>
            <TextField
              select
              label="Тип"
              value={editUser?.sub?.slug || ''}
              onChange={subTypeHandler}
              helperText="Выберите тип подписки"
            >
              {subTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                disabled={!editUser?.sub?.slug}
                label="Окончание подписки"
                value={editUser?.sub?.expireDate}
                onChange={subExpiredHandler}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      editUser?.sub?.expireDate
                        ? formatDistance(new Date(editUser?.sub?.expireDate), new Date(), {
                            addSuffix: true,
                            locale: ru,
                          })
                        : ''
                    }
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <ButtonGroup disabled={!editUser?.sub?.slug} style={{ marginBottom: 21 }} variant="contained">
              <Button size="small" onClick={() => addPeriod()}>
                +1 неделя
              </Button>
              <Button size="small" onClick={() => addPeriod('+1month')}>
                +1 месяц
              </Button>
              <Button size="small" onClick={() => addPeriod('+3month')}>
                +3 месяца
              </Button>
            </ButtonGroup>

            <Button
              onClick={saveHandler}
              disabled={loadingUserInfo || loadingUserUpdate}
              variant="contained"
              style={{ marginBottom: 21 }}
            >
              Сохранить
            </Button>
          </Stack>
        </Card>
      </Container>
    </Modal>
  );
};

const closeModalStyle = {
  position: 'absolute',
  right: 40,
  top: 10,
  zIndex: 2,
};

export default UserEdit;
