/* eslint-disable arrow-body-style */
import { TextField } from '@mui/material';

const DescriptionInput = ({ value, setValue }) => {
  return (
    <TextField
      value={value.properties.description}
      onChange={(e) => setValue({...value, properties: {...value.properties, description: e.target.value}})}
      placeholder="Описание"
      sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
    />
  );
};

export default DescriptionInput;
