/* eslint-disable react/prop-types */
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

// ----------------------------------------------------------------------

export default function AccountPopover({ open, onClose, anchorRef, displayName, email, logout }) {

  return (
    <MenuPopover
      open={open}
      anchorEl={anchorRef}
      onClose={onClose}
      sx={{
        p: 0,
        mt: 1.5,
        ml: 0.75,
        '& .MuiMenuItem-root': {
          typography: 'body2',
          borderRadius: 0.75,
        },
      }}
    >
      <Box sx={{ my: 1.5, px: 2.5 }}>
        <Typography variant="subtitle2" noWrap>
          {displayName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {email}
        </Typography>
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Divider sx={{ borderStyle: 'dashed' }} />

      <MenuItem onClick={logout} sx={{ m: 1 }}>
        Logout
      </MenuItem>
    </MenuPopover>
  );
}
