/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import {
  Autocomplete,
  Button,
  Card,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Modal,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Iconify from '../../components/Iconify';
import { JARYQ_TESTS_API, JARYQ_TESTS_ORIGIN } from '../../config';
import { useApi } from '../../hooks/useApi';
import { useNotify } from '../../hooks/useNotify';
import { getLocaleTestDuration, subjectsInfo, syntaxHighlight } from './utils';

const colorChanged = 'rgba(88, 67, 255, 0.1)';

export default function UserDetailsModal({ mutateUser, user, closeModal }) {
  const [dialogText, setDialogText] = useState(null);
  const [userData, setUserData] = useState(user);
  const [loading, api] = useApi();

  useEffect(() => {
    console.log('UPDATE USER');
    setUserData(user);
  }, [user]);

  const onUserChange = (props, subfield) => {
    if (subfield) setUserData({ ...userData, [subfield]: { ...userData[subfield], ...props } });
    else setUserData({ ...userData, ...props });
  };

  const isChanged = (field) => {
    if (userData === null || user === null) return false;

    const _ = (obj) => (Array.isArray(obj) ? obj.join(',') : obj);

    if (field.includes('.')) {
      const [a, b] = field.split('.');

      if (!userData[a] || !user[a]) return false;

      return _(userData[a][b]) !== _(user[a][b]);
    }
    return _(userData[field]) !== _(user[field]);
  };

  const openDialog = (text) => setDialogText(text);
  const closeDialog = () => setDialogText(null);

  const resetUserData = () => {
    setUserData(user);
  };

  const refreshUser = () => {
    mutateUser({});
  };

  const commonProps = { openDialog, userData, onUserChange, isChanged, mutateUser };

  return (
    <>
      <Dialog open={!!dialogText}>
        <DialogTitle>Информация</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{dialogText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} fullWidth>
            Ок
          </Button>
        </DialogActions>
      </Dialog>

      <Modal disableEnforceFocus open={!!user}>
        <Container
          maxWidth="lg"
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Card sx={{ padding: 3, background: '#ECECEC', overflow: 'visible' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={2}>
              <Stack direction="row" spacing={1}>
                <Typography variant="h4">Данные пользователя</Typography>
                <Tooltip title="Обновить данные">
                  <IconButton sx={{ height: 'fit-content' }} onClick={refreshUser}>
                    <Iconify icon="ic:round-sync" width={24} height={24} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Убрать изменения">
                  <IconButton color="info" sx={{ height: 'fit-content' }} onClick={resetUserData}>
                    <Iconify icon="radix-icons:reset" width={24} height={24} />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => openDialog(<JSONHiglight json={userData} />)} size="small" variant="text">
                  открыть в JSON
                </Button>
                <IconButton onClick={closeModal}>
                  <Iconify icon="eva:close-fill" width={24} height={24} />
                </IconButton>
              </Stack>
            </Stack>
            <Grid container spacing={2}>
              <UserInfoBlock {...commonProps} />
              <TestConfigBlock {...commonProps} />
              <VerificationBlock {...commonProps} />
              <TestStatusBlock {...commonProps} />
            </Grid>
          </Card>
        </Container>
      </Modal>
    </>
  );
}

/*
  =================
  BLOCKS COMPONENTS
  =================
*/
const UserInfoBlock = ({ userData, onUserChange, isChanged, mutateUser }) => {
  const [loading, api] = useApi();
  const { notify } = useNotify();
  const [resetReason, setResetReason] = useState('');

  const resetAccount = () => {
    console.log('RESET ACCOUNT', userData);
    api(`admin/users/${userData.id}/reset`, {
      method: 'POST',
      body: { reason: resetReason },
      success: ({ user }) => {
        mutateUser(user);
        notify('Аккаунт успешно сброшен');
      },
      error: (e) => {
        if (e.message === 'LOGS.BAD_REQUEST.RESET_ACCOUNT') notify('Укажите причину сброса', 'error');
        else notify(e.message, 'error');
      },
      origin: JARYQ_TESTS_API,
    });
  };

  const openUserAccount = () => {
    window.open(`${JARYQ_TESTS_ORIGIN}auth?login=${userData.login}&password=${userData.password}`, '_blank');
  };

  return (
    <Grid item xs={4}>
      <CardWrap>
        <Typography variant="h6">Основные данные</Typography>

        <Typography variant="body1">
          Логин: {userData?.login}{' '}
          <IconButton onClick={openUserAccount} size="small">
            <Iconify icon="fluent:open-16-filled" />
          </IconButton>
        </Typography>
        <Typography variant="body1">Пароль: {userData?.password}</Typography>
        <Typography variant="body1">Город: {userData?.city || '--'}</Typography>
        <Typography variant="body1">Школа: {userData?.school || '--'}</Typography>
        <Typography variant="body1">Класс: {userData?.schoolClass || '--'}</Typography>

        <ConfirmButton
          value={resetReason}
          onChange={setResetReason}
          variant="contained"
          color="error"
          onClick={resetAccount}
        >
          Сбросить аккаунт
        </ConfirmButton>
      </CardWrap>
    </Grid>
  );
};

const TestConfigBlock = ({ userData, onUserChange, isChanged }) => {
  return (
    <Grid item xs={8}>
      <CardWrap>
        <Typography variant="h6">Настройки теста</Typography>

        <SubjectInput
          isChanged={isChanged('testConfig.subjects')}
          value={userData?.testConfig?.subjects}
          onChange={onUserChange}
        />
        <ProbeInput
          isChanged={isChanged('testConfig.probe')}
          value={userData?.testConfig?.probe}
          schoolClass={userData?.schoolClass}
          onChange={onUserChange}
        />
        <LangInput
          isChanged={isChanged('testConfig.lang')}
          value={userData?.testConfig?.lang}
          onChange={onUserChange}
        />

        <DurationInput
          isChanged={isChanged('testConfig.duration')}
          value={userData?.testConfig?.duration}
          onChange={onUserChange}
        />
      </CardWrap>
    </Grid>
  );
};

const VerificationBlock = ({ userData, isChanged, openDialog, onUserChange, mutateUser }) => {
  const [loading, api] = useApi();
  const { notify } = useNotify();

  const verifyAccount = () => {
    console.log('VERIFY ACCOUNT', userData);
    api(`admin/users/${userData.id}/verify`, {
      success: ({ user }) => {
        mutateUser(user);
        notify('Аккаунт успешно подтвержден');
      },
      error: (e) => {
        notify(e.message, 'error');
      },
      origin: JARYQ_TESTS_API,
    });
  };

  return (
    <Grid item xs={4}>
      <CardWrap>
        <Typography variant="h6">Верификация</Typography>

        <FullNameInput isChanged={isChanged('fullName')} value={userData?.fullName} onChange={onUserChange} />
        <Typography variant="body1">Телефон: {userData?.phone || '--'}</Typography>
        <Typography variant="body1">
          Статус: {formatVerificationStatus(userData?.meta?.verification)}
          <IconButton
            sx={{ marginLeft: 0.5 }}
            size="small"
            onClick={() =>
              openDialog(
                <>
                  <Typography>Дата верификации: {formatDate(userData?.meta?.verificationDate)}</Typography>
                  <Typography>Номер верификации: {userData?.meta?.verificationData?.phone || '--'}</Typography>
                  <Typography>Результат СМС-центра: {userData?.meta?.verificationData?.response || '--'}</Typography>
                </>
              )
            }
          >
            <Iconify icon="lucide:info" />
          </IconButton>
        </Typography>
        <Typography variant="body1">
          <span style={{ background: isChanged('meta.fraudAttempts') && colorChanged }}>
            Уходы со страницы: {userData?.meta?.fraudAttempts ?? '--'}
          </span>
          {userData?.meta?.fraudAttempts > 0 && (
            <Tooltip title="Сбросить до нуля">
              <IconButton
                sx={{ marginLeft: 0.5 }}
                size="small"
                onClick={() => onUserChange({ fraudAttempts: 0 }, 'meta')}
              >
                <Iconify icon="radix-icons:reset" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body1">IP-адрес: {userData?.meta?.ipAddress || '--'}</Typography>

        {userData?.meta?.verification !== true && (
          <Tooltip title="Подтверждает верификацию пользователя, обычно используется для номеров, в ситуациях когда не приходит код">
            <Button disabled={loading} variant="contained" onClick={verifyAccount}>
              Подтвердить верификацию
            </Button>
          </Tooltip>
        )}
      </CardWrap>
    </Grid>
  );
};

const TestStatusBlock = ({ userData, mutateUser }) => {
  const [loading, api] = useApi();
  const { notify } = useNotify();
  const [resetReason, setResetReason] = useState('');

  const resetTest = () => {
    console.log('RESET TEST', userData);
    api(`admin/users/${userData.id}/reset-test`, {
      method: 'POST',
      body: { reason: resetReason },
      success: ({ user }) => {
        mutateUser(user);
        notify('Тест успешно сброшен');
      },
      error: (e) => {
        if (e.message === 'LOGS.BAD_REQUEST.RESET_TEST') notify('Укажите причину сброса теста', 'error');
        else notify(e.message, 'error');
      },
      origin: JARYQ_TESTS_API,
    });
  };

  const resumeTest = () => {
    console.log('RESUME TEST', userData);
    api(`admin/users/${userData.id}/resume-test`, {
      success: ({ user }) => {
        mutateUser(user);
        notify('Тест успешно продолжен');
      },
      error: (e) => {
        if (e.message === 'TEST.BAD_REQUEST.IS_ACTIVE') notify('Тест ещё активен', 'error');
        else notify(e.message, 'error');
      },
      origin: JARYQ_TESTS_API,
    });
  }

  return (
    <Grid item xs={8}>
      <CardWrap>
        <Grid container>
          <Grid item xs={6}>
            <Typography marginBottom={1} variant="h6">
              Данные теста:
            </Typography>
            <Stack spacing={0.8}>
              <Typography>
                Статус теста:
                <b style={{ marginLeft: '10px' }}>
                  {userData?.testStatus?.active === true && <Chip size="small" label="активен" color="warning" />}
                  {userData?.testStatus?.active === false && (
                    <Tooltip title={`Причина завершения теста: ${userData?.testStatus?.finishedReason || '--'}`}>
                      <Chip size="small" label="завершён" color="success" />
                    </Tooltip>
                  )}
                  {!userData?.testStatus && '--'}
                </b>
              </Typography>
              <Typography>Дата начала: {formatDate(userData?.testStatus?.startedDate)}</Typography>
              <Typography>Дата конца: {formatDate(userData?.testStatus?.finishedDate)}</Typography>
              <Typography>
                Длительность:{' '}
                {getLocaleTestDuration(
                  (new Date(userData?.testStatus?.finishedDate).getTime() -
                    new Date(userData?.testStatus?.startedDate).getTime()) /
                    1000
                ) || '--'}
              </Typography>
              <Typography>Баллов: {userData?.testStatus?.score ?? '--'}</Typography>
              <Typography>Макс. баллов: {userData?.testStatus?.maxScore ?? '--'}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography marginBottom={1} variant="h6">
              Ответы теста:
            </Typography>
            {userData?.meta?.saveAnswersDate && (
              <Typography>Дата последнего сохранения: {formatDate(userData?.meta?.saveAnswersDate)}</Typography>
            )}
            {!userData?.testAnswers && <Typography>Нет сохраненных ответов</Typography>}
            {userData?.testAnswers && (
              <List sx={{ height: 200, overflowY: 'auto' }}>
                {Object.keys(userData.testAnswers).map((queId) => (
                  <Stack direction="row" sx={{ paddingX: 2 }} justifyContent="space-between" key={queId}>
                    <Typography>{queId}</Typography>
                    <Typography>
                      {userData.testAnswers[queId]?.answer === null ? '--' : userData.testAnswers[queId]?.answer + 1}
                    </Typography>
                  </Stack>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
        {userData?.testStatus && (
          <Stack sx={{ marginTop: 1 }} spacing={1} direction="row">
            {userData?.testStatus?.active !== true && <Button onClick={resumeTest} disabled={loading} variant="contained">Возобновить/Продлить тест</Button>}
            <ConfirmButton
              disabled={loading}
              tooltip="Полностью сбрасывает данные теста с ответами, чтобы его можно было пройти заново"
              variant="contained"
              color="error"
              onClick={resetTest}
              onChange={setResetReason}
              value={resetReason}
            >
              Сбросить тест
            </ConfirmButton>
          </Stack>
        )}
      </CardWrap>
    </Grid>
  );
};

/*
  ==============
  FORMAT METHODS
  ==============
*/
const formatDate = (date) => (date ? moment(date).format('HH:mm:ss DD.MM.YYYY') : '--');
const formatVerificationStatus = (verification) => {
  if (verification === undefined) return '--';
  if (verification === true) return <Chip size="small" label="подтвержден" color="success" />;

  return <Chip size="small" label={`подтверждение номера (${verification})`} color="warning" />;
};

/*
  =================
  LAYOUT COMPONENTS
  =================
*/
const CardWrap = ({ children }) => (
  <Paper sx={{ padding: 1 }}>
    <Stack spacing={1}>{children}</Stack>
  </Paper>
);

const JSONHiglight = ({ json }) => {
  return (
    <>
      <pre
        style={{
          // width: 600,
        }}
        dangerouslySetInnerHTML={{ __html: syntaxHighlight(json) }}
      />
    </>
  );
};

/*
  =================
  INPUTS COMPONENTS
  =================
*/

const FullNameInput = ({ value, onChange, onResetFullName, isChanged }) => {
  const [_name, _surname] = value?.split('#') || ['', ''];

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        sx={{ background: isChanged && colorChanged }}
        label="Имя"
        size="small"
        value={_name}
        onChange={(e) => onChange({ fullName: `${e.target.value}#${_surname}` })}
      />
      <TextField
        sx={{ background: isChanged && colorChanged }}
        label="Фамилия"
        size="small"
        value={_surname}
        onChange={(e) => onChange({ fullName: `${_name}#${e.target.value}` })}
      />
    </Stack>
  );
};

const SubjectInput = ({ value = [], onChange, isChanged }) => (
  <Autocomplete
    sx={{ paddingY: 1, background: isChanged && colorChanged }}
    size="small"
    value={value.map((x) => ({ value: +x, label: subjectsInfo[x]?.label || 'undefined' }))}
    onChange={(_, v) => onChange({ subjects: v.map((x) => +x.value) }, 'testConfig')}
    renderInput={(params) => <TextField size="small" {...params} label="Предметы" />}
    multiple
    options={Object.keys(subjectsInfo).map((x) => ({ value: +x, label: subjectsInfo[x]?.label || 'undefined' }))}
  />
);

const ProbeInput = ({ value, onChange, schoolClass, isChanged }) => (
  <Stack direction={'row'} alignItems="center" spacing={1}>
    <Typography variant="body1">Пробник:</Typography>
    <ToggleButtonGroup
      sx={{ background: isChanged && colorChanged }}
      exclusive
      size="small"
      value={`${value}`}
      onChange={(_, v) => (v ? onChange({ probe: +v }, 'testConfig') : null)}
    >
      <ToggleButton sx={{ width: '10ch' }} size="small" value={`${schoolClass}1`}>{`${schoolClass}1`}</ToggleButton>
      <ToggleButton sx={{ width: '10ch' }} size="small" value={`${schoolClass}2`}>{`${schoolClass}2`}</ToggleButton>
      <ToggleButton sx={{ width: '10ch' }} size="small" value={`${schoolClass}3`}>{`${schoolClass}3`}</ToggleButton>
    </ToggleButtonGroup>
  </Stack>
);

const LangInput = ({ value, onChange, isChanged }) => (
  <Stack direction={'row'} alignItems="center" spacing={1}>
    <Typography variant="body1">Язык:</Typography>
    <ToggleButtonGroup
      sx={{ background: isChanged && colorChanged }}
      exclusive
      size="small"
      value={`${value}`}
      onChange={(_, lang) => (lang ? onChange({ lang }, 'testConfig') : null)}
    >
      <ToggleButton sx={{ width: '10ch' }} size="small" value={'ru'}>
        RU
      </ToggleButton>
      <ToggleButton sx={{ width: '10ch' }} size="small" value={'kz'}>
        KZ
      </ToggleButton>
    </ToggleButtonGroup>
  </Stack>
);

const DurationInput = ({ value, onChange, isChanged }) => (
  <Stack sx={{ paddingY: 1 }} direction="row" alignItems="center" spacing={1}>
    <TextField
      sx={{ background: isChanged && colorChanged }}
      size="small"
      label="Длительность теста"
      value={value}
      onChange={(e) => (!isNaN(e.target.value) ? onChange({ duration: +e.target.value }, 'testConfig') : null)}
    />
    <Typography>{getLocaleTestDuration(value)}</Typography>
  </Stack>
);

/*
  =================
  BUTTONS COMPONENTS
  =================
*/

const ConfirmButton = ({ disabled, value, onChange, onClick, color, variant, children, tooltip }) => {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);

  useEffect(() => {
    if (open === false && onChange) onChange('');
  }, [open]);

  return (
    <>
      <Dialog onClose={close} open={open}>
        <DialogTitle sx={{ width: 300, textAlign: 'center' }}>Вы уверены?</DialogTitle>
        {onChange && (
          <DialogContent>
            <TextField
              sx={{ marginY: 1 }}
              value={value}
              fullWidth
              onChange={(e) => onChange(e.target.value)}
              label="Причина сброса"
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              if (onClick) onClick();
              close();
            }}
          >
            Да
          </Button>
          <Button fullWidth onClick={close}>
            Нет
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title={tooltip || 'Возвращает аккаунт к исходному состоянию без данных пользователя и теста'}>
        <Button disabled={disabled} color={color} variant={variant} onClick={() => setOpen(true)}>
          {children}
        </Button>
      </Tooltip>
    </>
  );
};
