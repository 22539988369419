import { Container, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page';
import { LessonEditor } from '../../components/subjects';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';

export default function Lesson() {
  const { themeId, subjectSlug } = useParams();
  const addNotify = useContext(NotifyContext);

  const [subject, setSubject] = useState({});
  const [loadingSubject, fetchSubject] = useAjax((data, error) => {
    if (error) {
      addNotify(data.msg || 'Ошибка', 'error');
      console.log(data.error);
      return;
    }

    console.log(data);
    setSubject(data);
  });

  useEffect(() => {
    fetchSubject(`subjects/${subjectSlug}`);
  }, []);

  const [theme, setTheme] = useState({});
  const [loadingTheme, fetchTheme] = useAjax((data, error) => {
    if (error) {
      addNotify(data.msg || 'Ошибка', 'error');
      console.log(data.error);
      return;
    }

    console.log(data);
    setTheme(data);
  });

  useEffect(() => {
    fetchTheme(`themes/${themeId}/lesson`);
  }, []);

  return (
    <Page title={theme.label}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center" divider={<span style={{ opacity: 0.5 }}>—</span>}>
            <Link href="/subjects" color="inherit" underline="none" variant="h4">
              Предметы
            </Link>
            <Link
              href={`/subjects/${subject.slug}`}
              color="inherit"
              underline="none"
              variant="h4"
              sx={{ background: subject.color, ...colorLinkStyle }}
            >
              {subject.label}
            </Link>
            <Typography variant="subtitle1" sx={{ opacity: 0.6 }}>
              {theme.label}
            </Typography>
          </Stack>
        </Stack>

        {loadingTheme && loadingSubject ? <LinearProgress /> : <LessonEditor theme={theme} />}
      </Container>
    </Page>
  );
}

const colorLinkStyle = {
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
};
