/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import { Link } from '@mui/material';
import React, { useContext, useLayoutEffect, useRef } from 'react';
import { MathJaxBaseContext } from 'better-react-mathjax';

import './QuestionPreview.css';

export default function QuestionPreview({ question, answers, correct, solution, themeLabel }) {
  const mjContext = useContext(MathJaxBaseContext);
  const questionRef = useRef();

  useLayoutEffect(() => {
    const typeset = () => {
      if (mjContext && questionRef.current) {
        mjContext.promise.then((mathJax) => {
          mathJax.startup.promise.then(() => {
            try {
              if (questionRef.current) {
                console.log(mathJax);
                mathJax.typesetClear([questionRef.current]);
                mathJax.typesetPromise([questionRef.current]);
              }
            } catch (error) {
              console.warn('MathLatex ', error);
            }
          });
        });
      }
    };
    typeset();
  });

  return (
    <div ref={questionRef} className="test-content block">
      <div className="question" dangerouslySetInnerHTML={{ __html: question || '' }} />
      <div className="answers-wrap">
        {answers.map((ans, index) => (
          <div
            key={index}
            className={`ui-checkbox ui-checkbox--medium answers-item ${
              correct.includes(index) ? 'answers-item--correct' : ''
            }`}
          >
            <input type="checkbox" id={index} />
            <label htmlFor={index}>
              <div className="answers-item__label" dangerouslySetInnerHTML={{ __html: ans || '' }} />
            </label>
          </div>
        ))}
      </div>
      <div className="solution">
        <h4>Решение</h4>
        <div dangerouslySetInnerHTML={{ __html: solution || '' }} />
        <Link to="#">
          <h5>{themeLabel}</h5>
        </Link>
      </div>
    </div>
  );
}
