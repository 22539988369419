import { useCallback, useEffect, useState } from 'react';
import { API } from '../config';
import { useAuth } from './useAuth';

export const useAjax = (callback, isApi = true, defaultLoading = false) => {
  const [loading, setLoading] = useState(defaultLoading);
  const [controller] = useState(new AbortController());
  const { token } = useAuth();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const ajax = useCallback(
    async (url, method = 'GET', body = null, headers = {}, type = 'json', clearUrl = false) => {
      setLoading(true);

      let link = isApi ? `${API}${url}` : url;
      if (clearUrl) link = url;
      try {
        if (body) {
          body = JSON.stringify(body);
          headers['Content-Type'] = 'application/json';
        }
        if (token && !('Authorization' in headers)) {
          headers.Authorization = `Bearer ${token}`;
        }

        const response = await fetch(link, { method, body, headers, signal: controller.signal });

        let data = {};

        if (type === 'json') data = await response.json();
        if (type === 'text') data = await response.text();

        setLoading(false);
        callback(data, ![200, 201, 202].includes(response.status));
      } catch (e) {
        if (e.name === 'AbortError') return;
        setLoading(false);
        callback({ message: 'Непредвиденная ошибка :(', error: e.message }, true);
      }
    },
    [callback, controller, isApi]
  );

  return [loading, ajax];
};
