/* eslint-disable react/prop-types */
import { LinearProgress } from '@mui/material';
import { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useAjax } from '../hooks/useAjax';
import { useAuth } from '../hooks/useAuth';
import { NotifyContext } from './NotifyContext';

export const AuthContext = createContext({
  token: null,
  login: () => {},
  logout: () => {},
  isAuth: false,
  meta: null,
  initMeta: () => {},
});

export const AuthProvider = ({ children }) => {
  const { token, login, logout } = useAuth();
  const [meta, initMeta] = useState(null);
  const addNotify = useContext(NotifyContext);
  const [loading, fetchMeta] = useAjax((data, error) => {
    if (error) {
      logout()
      // if (data.name === 'TokenExpiredError') logout();
      return;
    }

    console.log(data, error, 'update context');
    if (data?.role?.slug === 'student') {
      console.log('exit not access');
      addNotify('Доступ запрещен', 'error')
      logout()
      return;
    }
    initMeta(data);
  }, true, true);

  useLayoutEffect(() => {
    const Authorization = `Bearer ${token}`;
    if (token) fetchMeta('user', 'GET', null, { Authorization });
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        isAuth: !!token,
        meta,
        initMeta,
      }}
    >
      {(loading && token) ? <LinearProgress /> : children}
    </AuthContext.Provider>
  );
};
