import { Box, Button, ButtonBase, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import palette from '../../theme/palette';

const GeoItem = styled(ButtonBase)(({ theme }) => ({
  display: 'block',
  borderRadius: 8,
  flexShrink: 0,
  textAlign: 'left',
  padding: 10,
  boxShadow: theme.shadows[1],
  transition: '0.4s ease',
}));

const GeoLibrary = ({ sx, activeObj, onChange, onAdd, onChangeShow, geojson }) => {
  const [toggles, setToggles] = useState(geojson.map(() => false));

  const showGeoHandle = (obj, i) => {
    const newToggles = [...toggles];
    newToggles[i] = !newToggles[i];
    setToggles(newToggles);
    onChangeShow(geojson.filter((obj, j) => newToggles[j]));
  };

  return (
    <Box sx={{ ...sx }}>
      <Typography marginBottom={1} typography="h5">
        Библиотека GeoJSON <Button onClick={onAdd}>Добавить</Button>
      </Typography>
      <Stack direction="row" spacing={1} sx={{ overflowX: 'auto', paddingBottom: 1 }}>
        {geojson.map((obj, i) => (
          <Stack key={obj.properties.id}>
            <GeoItem
              onClick={() => onChange(obj)}
              disabled={activeObj?.properties?.id === obj.properties.id}
              sx={{
                ...(activeObj?.properties?.id === obj.properties.id
                  ? { backgroundColor: palette.primary.main, color: 'white' }
                  : {}),
              }}
            >
              <Typography fontWeight={500}>{obj.properties.title}</Typography>
              <Typography sx={{ opacity: 0.6 }}>{obj.geometry.type}</Typography>
            </GeoItem>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

/*
<ToggleButton value={'check'} selected={toggles[i]} onChange={() => showGeoHandle(obj, i)}>
  <Iconify icon='solar:map-linear' />
</ToggleButton>
*/

export default GeoLibrary;
