// @mui
import { styled } from '@mui/material/styles';
import { Card,  Typography } from '@mui/material';
// hooks
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import LoginForm from '../components/auth/LoginForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <Logo sx={{position: 'absolute', left: '50%', top: '5%', transform: 'translateX(-50%)'}} />
        <SectionStyle>
          <Typography variant="h4" align='center' sx={{marginBottom: 4}}>
            Вход в систему
          </Typography>
          <LoginForm />
        </SectionStyle>
      </RootStyle>
    </Page>
  );
}
