/* eslint-disable react/prop-types */
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';

export default function QuestionTop({ label, softDelete, handleChangeDisable, headless, headlessLabel='', handleResetQuestion, handlePreview, moreOptions }) {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography variant="h6" component="div">
        {headless ? headlessLabel : label }
      </Typography>


      {!headless && !softDelete && (
        <Tooltip disableInteractive title="Удалить вопрос">
          <IconButton onClick={handleChangeDisable}>
            <Iconify className="icon--disable" icon="fluent:delete-12-regular" width={24} height={24} />
          </IconButton>
        </Tooltip>
      )}
      {!headless && softDelete && (
        <Tooltip disableInteractive title="!Вопроса не будет в тестах">
          <IconButton onClick={handleChangeDisable}>
            <Iconify className="icon--disable" icon="ic:sharp-disabled-visible" width={24} height={24} />
          </IconButton>
        </Tooltip>
      )}


      {!headless && (
        <Tooltip disableInteractive title="Вернуть всё как было">
          <IconButton onClick={handleResetQuestion}>
            <Iconify icon="bx:reset" width={24} height={24} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip disableInteractive title="Включить предпросмотр">
        <IconButton onClick={handlePreview}>
          <Iconify icon="ic:outline-preview" width={24} height={24} />
        </IconButton>
      </Tooltip>
      {moreOptions}
    </Stack>
  );
}
