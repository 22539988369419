import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, LinearProgress, styled, Typography } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import { ImageManager } from '../imageManager';
import MathTypeEditor from '../MathTypeEditor';
//
export default function LessonEditor({ theme }) {
  const [searchParams] = useSearchParams();
  const isKz = searchParams.has('kz');

  const { lesson } = theme;
  const editor = useRef(null);
  const [updatedDate, setUpdatedDate] = useState('');
  const [content, setContent] = useState('');
  useEffect(() => {
    setContent(isKz ? lesson?.content_kz : lesson?.content);
    setUpdatedDate(lesson?.updated);
  }, [theme]);

  const config = {
    readonly: false,
    placeholder: 'Start typings...',
  };

  const addNotify = useContext(NotifyContext);
  const [loadingUpdate, fetchLessonUpdate] = useAjax((data, error) => {
    if (error) {
      console.log(data.msg || 'Ошибка при обновлении');
      console.warn(data.error);
      return;
    }

    if (!data) return;
    setUpdatedDate(data.updated);
    if (data.type === 'update') {
      addNotify('Урок был успешно обновлен', 'success');
      return;
    }
    if (data.type === 'create') {
      addNotify('Урок был успешно добавлен', 'success');
    }
  });

  const handleBlurEditor = (val) => {
    setContent(val);
  };

  const handleUpdateLesson = () => {
    fetchLessonUpdate(`themes/${theme.id}/lesson${isKz ? '?lang=kz' : ''}`, 'POST', { content });
    console.log(`themes/${theme.id}/lesson${isKz ? '?lang=kz' : ''}`);
  };

  return (
    <>
      <Grid container spacing={4} sx={{ marginTop: 0 }}>
        <Grid item xs={9}>
          <EditorCard>
            {loadingUpdate && <LinearProgress />}
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              onBlur={handleBlurEditor} // preferred to use only this option to update the content for performance reasons
            />
          </EditorCard>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <LoadingButton
              loading={loadingUpdate}
              onClick={handleUpdateLesson}
              variant="contained"
              fullWidth
              size="large"
            >
              Сохранить{isKz ? ' KZ' : ''}
            </LoadingButton>
            <Typography variant="subtitle2" sx={{ marginTop: 2, textAlign: 'center', opacity: 0.6 }}>
              Последнее изменение: {localeDate(updatedDate)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ImageManager isCopyBuffer />
      <MathTypeEditor
        style={{
          position: 'fixed',
          top: 117,
          transform: 'translateX(-100%)',
          opacity: 1,
          pointerEvents: 'all',
        }}
      />
    </>
  );
}

const localeDate = (date) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timezone: 'UTC',
  };
  if (!date) return '-';
  return new Date(date).toLocaleString('ru', options);
};

const EditorCard = styled(Card)(() => ({
  '& iframe': {
    width: '100%',
  },
  '.jodit_sticky>.jodit-toolbar__box': {
    position: 'fixed !important',
  },
}));
