import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, TableCell, TableRow } from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';

export default function DraggableItem({ theme }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: theme.id });

  return (
    <TableRow
      {...attributes}
      ref={setNodeRef}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        '&:hover': {
            backgroundColor: '#2065d10a',
        },
      }}
      style={{ transform: CSS.Transform.toString(transform), transition }}
    >
      <TableCell>
        <Button {...listeners} sx={{ minWidth: 0 }}>
          <Iconify icon="icon-park:drag" width={24} height={24} />
        </Button>
      </TableCell>
      <TableCell>{theme.label}</TableCell>
      <TableCell align="center">{theme.props || '-'}</TableCell>
    </TableRow>
  );
}
  