import { Box, ImageList, ImageListItem, LinearProgress, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import VideoUploader from './VideoUploader';

// eslint-disable-next-line react/prop-types
export default function VideosSection({ closeManager, sx, isCopyBuffer }) {
  const addNotify = useContext(NotifyContext);
  const [videos, setVideos] = useState([]);
  const [loadingVideos, getVideos] = useAjax((data, error) => {
    if (error) {
      addNotify('Ошибка при загрузке последних видео', 'error');
      console.warn('Fetch error getVideos', data.error);
      return;
    }
    setVideos(data.data);
  }, false);

  useEffect(() => {
    getVideos('https://api.vimeo.com/me/videos?per_page=9', 'GET', null, {
      Authorization: 'Bearer 03c2d99add48b701818c08d2c9673d2c',
    });
  }, []);

  return (
    <Box sx={{ width: 400, ...sx }}>
      <VideoUploader
        updateVideos={() =>
          getVideos('https://api.vimeo.com/me/videos?per_page=9', 'GET', null, {
            Authorization: 'Bearer 03c2d99add48b701818c08d2c9673d2c',
          })
        }
      />
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" component="div">
          Последние видео
        </Typography>
        <Typography variant="caption" component="div">
          Кликните, чтобы скопировать видео
        </Typography>
        {loadingVideos ? (
          <LinearProgress />
        ) : (
          <ImageList sx={{ marginTop: 1 }} gap={8} cols={3} rowHeight={128}>
            {videos.map((video, index) => (
              <ImageListItem
                key={index}
                className="imageManager__item"
                onClick={() => {
                  const htmlEmbed = video?.embed?.html.replace('allowfullscreen', 'allowfullscreen="true"')
                  sessionStorage.setItem(
                    'copyMediaSrc',
                    htmlEmbed
                  );
                  if (isCopyBuffer) navigator.clipboard.writeText(htmlEmbed)
                  addNotify(`Видео ${video?.name} скопировано`);
                  closeManager();
                }}
              >
                <img
                  src={video?.pictures?.base_link}
                  srcSet={video?.pictures?.base_link}
                  alt={video?.name}
                  loading="lazy"
                  style={{
                    height: '90px',
                    objectFit: 'contain',
                    border: '1px solid #eeeeee',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                />
                <div className="imageManager__item-name">{video?.name}</div>
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>
    </Box>
  );
}
