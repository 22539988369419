import { Card, Container, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Iconify from './Iconify';

ModalEditor.propTypes = {
  size: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.any,
};

export default function ModalEditor({ size, open, handleClose, children }) {
  return (
    <Modal
      disableEnforceFocus
      open={open}
    >
      <Container maxWidth={size} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CloseModalIcon onClick={handleClose}>
          <Iconify icon="eva:close-fill" width={24} height={24} />
        </CloseModalIcon>
        <Card sx={{ padding: 3, background: '#e1f5fe', overflow: 'visible' }}>{children}</Card>
      </Container>
    </Modal>
  );
}

const CloseModalIcon = styled(IconButton)(() => ({
  position: 'absolute',
  right: 40,
  top: 10,
  zIndex: 2,
}));
