/* eslint-disable react/prop-types */
import { LoadingButton } from '@mui/lab';
import React from 'react';

export default function SaveButton({ label, disabled, loading, btnHandler }) {
  return (
    <LoadingButton disabled={disabled} loading={loading} variant="contained" size="large" onClick={btnHandler}>
      {label || 'Сохранить'}
    </LoadingButton>
  );
}
