/* eslint-disable camelcase */
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';

const DateRange = ({ range, onChange, options }) => (
  <Box>
    <Typography color="white">Временной отрезок</Typography>
    <Stack direction="row" spacing={1} alignItems="center">
      <Autocomplete
        disablePortal
        value={range.hash}
        onChange={(event, hash) => {
          console.log(hash);
          if (hash) onChange({hash: hash.value});
        }}
        options={options}
        sx={{ width: '30ch', display: 'inline-block', '& .MuiInputBase-root': { background: 'white' } }}
        renderInput={(params) => <TextField {...params} />}
        isOptionEqualToValue={(option, value) => option.label.includes(value)}
        filterOptions={(options, value) =>
          options.sort((a, b) => a.value - b.value).filter((x) => x.label.includes(value.inputValue))
        }
      />
      <Typography color="white">—</Typography>
      <Autocomplete
        disablePortal
        value={range.hash_end}
        onChange={(event, hash_end) => {
          if (hash_end) onChange({hash_end: hash_end.value});
        }}
        options={options}
        sx={{ width: '30ch', display: 'inline-block', '& .MuiInputBase-root': { background: 'white' } }}
        renderInput={(params) => <TextField {...params} />}
        isOptionEqualToValue={(option, value) => option.label.includes(value)}
        filterOptions={(options, value) =>
          options
            .sort((a, b) => a.value - b.value)
            .filter((x) => +x.value >= +range.hash && x.label.includes(value.inputValue))
        }
      />
    </Stack>
  </Box>
);

export default DateRange;
