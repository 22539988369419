/* eslint-disable prefer-template */
/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-arrow-callback */

export const subjectsInfo = {
  3: {
    slug: 'phys',
    color: 'linear-gradient(154.96deg, #85DEF1 15.45%, #63B4EF 94.54%)',
    label: 'Физика',
  },
  4: {
    slug: 'info',
    color: 'linear-gradient(140.38deg, #796EF9 14.52%, #3B31B7 98.89%)',
    label: 'Информатика',
  },
  8: {
    slug: 'math2',
    color: 'linear-gradient(136.58deg, #6472F2 -0.62%, #249CF2 99.46%)',
    label: 'Математика',
  },
  9: {
    slug: 'geo',
    color: 'linear-gradient(152.95deg, #63C4B8 15.49%, #2F8B97 93.94%)',
    label: 'География',
  },
  10: {
    slug: 'hist-kz',
    color: 'linear-gradient(136.58deg, #F2A864 -0.62%, #F2B824 99.46%)',
    label: 'История Казахстана',
  },
  11: {
    slug: 'chem',
    color: 'linear-gradient(152.95deg, #8E63C4 15.49%, #5D2F97 93.94%)',
    label: 'Химия',
  },
  12: {
    slug: 'math-lit',
    color: 'linear-gradient(154.96deg, #CAA0F4 15.45%, #D363EF 94.54%)',
    label: 'Мат. грамотность',
  },
  13: {
    slug: 'read-lit',
    color: 'linear-gradient(154.96deg, #8691EE 15.45%, #7944E9 94.54%)',
    label: 'Грамотность чтения',
  },
  14: {
    slug: 'hist',
    color: 'linear-gradient(140.38deg, #6EF0F9 14.52%, #31B78F 98.89%)',
    label: 'История',
  },
  15: {
    slug: 'eng',
    color: 'linear-gradient(140.38deg, #F07AD6 14.52%, #E54FFE 98.89%)',
    label: 'Английский',
  },
  16: {
    slug: 'bio',
    color: 'linear-gradient(152.95deg, #65C463 15.49%, #2F9752 93.94%)',
    label: 'Биология',
  },
  17: {
    slug: 'law',
    color: 'linear-gradient(140.38deg, #F1BBE9 14.52%, #A87ABD 98.89%)',
    label: 'Основы права',
  },
  18: {
    slug: 'kaz',
    color: 'linear-gradient(140deg, #F98F6E 0%, #B75131 100%)',
    label: 'Казахский',
  },
  22: {
    slug: 'science',
    color: 'linear-gradient(140deg, #C9BBF1 14.52%, #7ABD95 98.89%)',
    label: 'Естествознание',
  },
};

/**
 *
 * @param {number} amount
 * @param {string[]} cases For slavic languages: ['яблоко', 'яблока', 'яблок']
 * @returns {string}
 */
export function pluralize(amount, cases, show_amount = true) {
  if (amount === undefined) return '';
  amount = Math.round(amount);
  if (cases.length === 1) return `${amount} ${cases[0]}`;
  const indexes = [2, 0, 1, 1, 1, 2];
  const mod100 = amount % 100;
  const mod10 = amount % 10;
  const index = mod100 > 4 && mod100 < 20 ? 2 : indexes[mod10 < 5 ? mod10 : 5];
  return `${show_amount ? Math.floor(amount) + ' ' : ''}${cases[index]}`;
}

export const getLocaleTestDuration = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';
  if (days > 0) {
    result += pluralize(days, ['день', 'дня', 'дней']) + ' ';
  }
  if (hours > 0 || days > 0) {
    result += pluralize(hours, ['час', 'часа', 'часов']) + ' ';
  }
  if (minutes > 0 || hours > 0 || days > 0) {
    result += pluralize(minutes, ['минута', 'минуты', 'минут']) + ' ';
  }
  if (remainingSeconds > 0) result += pluralize(remainingSeconds, ['секунда', 'секунды', 'секунд']);
  return result;
};

export function syntaxHighlight(json, indent = 2) {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, indent);
  }

  try {
    console.log(JSON.parse(json));
  } catch (e) {
    console.log('JSON ERROR');
  }

  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    }
  );
}
