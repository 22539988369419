/* eslint-disable react/prop-types */
import { Collapse, Stack, TableCell, TableRow } from '@mui/material'
import React from 'react'

export default function QuestionEdit({disable, isExpand, children, scrollbar}) {

  return (
    <TableRow sx={{ borderTop: 'none' }} className={`questionEdit ${disable ? 'questionEdit--disable' : ''}`}>
        <TableCell style={{ padding: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isExpand} timeout="auto" unmountOnExit>
            <Stack spacing={3} sx={{ padding: 2, backgroundColor: '#e1f5fe' }} className={scrollbar ? 'questionEdit-scroll': ''}>
                {children}
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
  )
}
