/* eslint-disable no-case-declarations */
// material
import { Card, colors, Container, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import 'moment/locale/ru';
import { useContext } from 'react';
import useGetApi from '../hooks/useGetApi';
// components
import Page from '../components/Page';
import { AuthContext } from '../contexts/AuthContext';

const getColor = (page) => {
  if (page === 'lesson') return colors.amber[300];
  if (page === 'practice') return colors.amber[600];
  if (page === 'test') return colors.yellow[300];
  if (page === null) return 'transparent';
  return colors.blue[400];
};

const getParams = (e) => {
  if (e.page === 'lesson' || e.page === 'practice') {
    const match = e.path.match(/\/subjects\/(.+)\/(\d+)_/);
    if (match) {
      return { subject: match[1], themeId: match[2] };
    }
  }

  return {}
};

export default function Activity() {
  const { meta } = useContext(AuthContext);
  const [loadingSubjects, subjects] = useGetApi(`subjects/themes`, []);
  const [loading, activity] = useGetApi('user/activity/all', {});
  const [loadingUsers, users] = useGetApi('user/all', []);
  const [loadingSubscribers, subscribers] = useGetApi('user/subscribers', []);

  const getUserTitle = (id) => {
    const res = users.find(user => user.id === +id)
    if (!res) return id
    return `[${id}] ${res.email} - ${res.name}`
  }

  const getSubjectColor = (e) => {
    const { subject } = getParams(e)
    return subjects.filter(s => s.slug === subject)[0]?.color
  }

  const getTheme = (slug, id) => subjects.filter(s => s.slug === slug)[0]?.themes?.filter(t => t.id === +id)[0] || {}

  const getTitle = (e) => {
    const params = getParams(e)
    switch (e.page) {
      case 'lesson':
      case 'practice':
        const theme = getTheme(params.subject, params.themeId)
        return theme.label || theme.label_kz

      case 'dashboard':
        return 'Дешборд'

      case 'mytests':
        return 'Мои тесты'

      case 'test':
        return `Тест`

      case 'leaderboard':
        return 'Лидерборд'

      case 'profile':
        return 'Профиль'

      case 'statistic':
        return 'Статистика'

      default:
        return e.page
    }
  }

  return (
    <Page title="Activity">
      <Container maxWidth="xl">
        <Stack direction="row" spacing={3} alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography variant="h4">Активность</Typography>
          <Box
            sx={{
              padding: '3px 10px',
              borderRadius: 5,
              color: 'white',
              backgroundColor: colors.blue.A700,
            }}
          >
            Онлайн: {meta?.online}
          </Box>
        </Stack>
        <Stack
          style={{
            overflowX: 'auto',
            paddingBottom: 30,
          }}
          direction="row"
          spacing={4}
        >
          {Object.keys(activity).filter(user => subscribers.includes(+user) && user > 26).map((user, i) => (
            <Card
              key={i}
              sx={{
                flexShrink: 0,
                width: 600,
                padding: 2,
              }}
            >
              <Typography textAlign="center">{getUserTitle(user)}</Typography>
              <Divider sx={{ marginY: 1 }} />
              <Stack spacing={2}>
                {activity[user].map((row, i) => (
                  <Stack key={`${row.date}:${i}`} direction="row" alignItems="center" spacing={2}>
                    <Box>
                      <Typography textAlign="center">{moment(row.date).format('DD')}</Typography>
                      <Typography>{moment(row.date).format('DD MMMM').slice(3)}</Typography>
                    </Box>
                    <Stack
                      direction="row"
                      sx={{
                        width: '100%',
                        height: 20,
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        borderRadius: 10,
                      }}
                    >
                      {row.timeline.map((x, i, arr) => (
                        <Tooltip key={i} title={`${getTitle(x)}  [${moment.duration(x.duration).humanize()}]`}>
                          <Box
                            sx={{
                              width: x.path ? `${(x.duration / row.durationAll) * 100}%` : '5%',
                              height: '100%',
                              backgroundColor: getColor(x.page),
                              borderTopLeftRadius: arr[i - 1]?.path ? 0 : '15px',
                              borderBottomLeftRadius: arr[i - 1]?.path ? 0 : '15px',
                              borderTopRightRadius: arr[i + 1]?.path ? 0 : '15px',
                              borderBottomRightRadius: arr[i + 1]?.path ? 0 : '15px',
                              ...(['lesson', 'practice'].includes(x.page) ? {background: getSubjectColor(x)} : {})
                            }}
                          />
                        </Tooltip>
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Card>
          ))}
        </Stack>
      </Container>
    </Page>
  );
}
