import { Alert } from '@mui/material';
import React, { useState } from 'react';

const localname = 'updateAlert1.2-hidden'

const UpdateAlert = ({ msg }) => {
  const [hidden, setHidden] = useState(!!(+localStorage.getItem(localname)));

  const closeHandler = () => {
    localStorage.setItem(localname, '1')
    setHidden(true)
  }

  if (hidden) return null;

  return (
    <Alert onClose={closeHandler} sx={{ mb: 2 }} severity="info">
      {msg}
    </Alert>
  );
};

export default UpdateAlert;
