// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Дешборд',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'База вопросов',
    path: '/questions',
    icon: getIcon('fa6-solid:database'),
    access: 'questions'
  },
  {
    title: 'Предметы',
    path: '/subjects',
    icon: getIcon('wpf:books'),
    access: 'subjects'
  },
  {
    title: 'Пользователи',
    path: '/users',
    icon: getIcon('heroicons:users-solid'),
    access: 'users'
  },
  {
    title: 'Активность',
    path: '/activity',
    icon: getIcon('fe:activity'),
    access: 'activity',
  },
  {
    title: 'Медиа',
    path: '/media',
    icon: getIcon('ooui:image-gallery'),
    access: 'media',
  },
  {
    title: 'Карта',
    path: '/map-editor',
    icon: getIcon('solar:map-bold'),
    access: 'mapEditor',
  },
  {
    title: 'Jaryq Tests',
    path: '/easy-tests',
    icon: getIcon('ph:star-four-fill'),
    access: 'easyTestUsers',
  },
];

export default navConfig;
