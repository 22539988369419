import { Box, ImageList, ImageListItem, LinearProgress, Typography } from '@mui/material';
import { useContext } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { NotifyContext } from '../../contexts/NotifyContext';
import useGetApi from '../../hooks/useGetApi';

// eslint-disable-next-line react/prop-types
export default function ImagesSection({ closeManager, sx, isCopyBuffer }) {
  const addNotify = useContext(NotifyContext);
  const [loadingPhotos, photos, updatePhotos] = useGetApi('uploads/photos?limit=9', []);

  const getUploadParams = () => ({ url: 'https://api-old.jaryq-academy.kz/uploads/photos' });

  const handleChangeStatus = (fileData, status) => {
    if (status === 'done') {
      fileData.remove();
      addNotify(`Файл ${fileData.file.name} успешно загружен`, 'success');
      updatePhotos();
    }
  };

  return (
    <Box sx={{ width: 400, ...sx }}>
      <Dropzone
        styles={{
          dropzone: {
            overflow: 'visible',
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
          },
          inputLabel: {
            fontFamily: 'inherit',
            fontSize: '16px',
            textAlign: 'center',
            color: '#bdbdbd',
          },
          inputLabelWithFiles: {
            width: 'calc(100% - 16px)',
            margin: '8px',
            border: '1px solid rgba(51, 102, 255, 0.5)',
            backgroundColor: 'white',
            color: '#3366FF',
            borderRadius: '8px',
            fontSize: '0.875rem',
            fontFamily: 'inherit',
          },
        }}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputContent={(files, extra) => (extra.reject ? 'Только картинки!' : 'Перетащите файлы или кликните')}
        maxFiles={10}
        validate={(fileMeta) => {
          if (fileMeta.file.size > 1024 * 1024) return 'Max size 1MB';
        }}
        accept="image/*"
        SubmitButtonComponent={null}
        inputWithFilesContent={'Добавить еще'}
      />
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" component="div">
          Последние изображения
        </Typography>
        <Typography variant="caption" component="div">
          Кликните, чтобы скопировать изображение
        </Typography>
        {loadingPhotos ? (
          <LinearProgress />
        ) : (
          <ImageList sx={{ marginTop: 1 }} gap={8} cols={3} rowHeight={128}>
            {photos.map((el) => (
              <ImageListItem
                key={el}
                className="imageManager__item"
                onClick={() => {
                  sessionStorage.setItem('copyMediaSrc', `<img src="https://jaryq-academy.kz/uploads/${el}" />`);
                  if (isCopyBuffer) navigator.clipboard.writeText(`https://jaryq-academy.kz/uploads/${el}`);
                  addNotify(`Картинка скопирована`);
                  closeManager();
                }}
              >
                <img
                  src={`https://jaryq-academy.kz/uploads/${el}`}
                  srcSet={`https://jaryq-academy.kz/uploads/${el}`}
                  alt={el}
                  loading="lazy"
                  style={{
                    height: '128px',
                    objectFit: 'contain',
                    border: '1px solid #eeeeee',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>
    </Box>
  );
}
