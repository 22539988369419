/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import {
  LangInput,
  NumberInput,
  QuestionAnswers,
  QuestionEdit,
  QuestionHead,
  QuestionInput,
  QuestionSolution,
  QuestionTop,
  SaveButton,
  SubjectInput,
  ThemeInput,
} from '../questionItem';
import ContextRange from '../questionItem/ContextRange';

const QuestionRowEntContext = ({
  headless = false,
  loading,
  handleSendData,
  subjectsInfo,
  indicator,

  // FIELDS
  id = 0,
  probe = 0,
  subjectID = 0,
  question = '',
  solution = '21-25',
  disable = false,
  lang = 'ru',
}) => {
  const [isExpand, setIsExpand] = useState(!!headless);

  const initObject = {
    probe,
    subjectID,
    question,
    solution, // range of questions
    disable
  };

  const [fields, setFields] = useState(initObject);

  // UTILS
  const handleSaveQuestion = () => {
    const data = {
      id,
      probe: fields.probe,
      probeNumber: 0,
      question: fields.question,
      subjectID: fields.subjectID,
      themeID: 0,
      answers: '',
      correct: '',
      solution: fields.solution,
      disable: fields.disable,
      lang,
    };
    console.log('save data ent', data);
    handleSendData(data)
  };
  const resetQuestionData = () => {
    setFields(initObject);
  };
  const noChanges = () =>
    fields.probe === probe &&
    fields.subjectID === subjectID &&
    fields.question === question &&
    fields.disable === disable &&
    fields.solution === solution;

  // HANDLES
  const handleQuestion = (html) => {
    setFields({ ...fields, question: html });
  };
  const handleProbe = (newValue) => {
    setFields({ ...fields, probe: newValue });
  };
  const handleSubjectID = (e) => {
    const newSubjectID = +e.target.value;
    setFields({ ...fields, subjectID: newSubjectID });
  };

  const handleDisable = () => {
    setFields({ ...fields, disable: !fields.disable });
  };
  const handleRange = (e, newValue) => {
    setFields({ ...fields, solution: newValue.join('-') });
  }

  return (
    <>
      {!headless && (
        <QuestionHead
          id={id}
          indicator={indicator}
          themeID={'Контекст'}
          themeLabel={'Контекстный вопрос'}
          subject={subjectsInfo.subjects.filter((x) => x.id === subjectID)[0]}
          question={question}
          disable={disable}
          setIsExpand={setIsExpand}
          isExpand={isExpand}
        />
      )}
      <QuestionEdit disable={fields.disable} isExpand={isExpand} scrollbar={headless}>
        <QuestionTop
          label={`Редактирование контекста ID-${id} [${lang.toUpperCase()}]`}
          headlessLabel={`Добавление контекста для вопросов [${lang.toUpperCase()}]`}
          headless={headless}
          handleChangeDisable={handleDisable}
          handleResetQuestion={resetQuestionData}
        />

        <QuestionInput value={fields.question} onChange={handleQuestion} />

        <Stack spacing={2} direction="row" alignItems="center">
          <SubjectInput value={fields.subjectID} onChange={handleSubjectID} options={subjectsInfo.subjects} />
          <NumberInput label="Номер пробника" value={fields.probe} onChange={handleProbe} />
          <ContextRange value={fields.solution.split('-')} onChange={handleRange} />
        </Stack>

        <SaveButton label={headless ? 'Добавить' : 'Сохранить'} disabled={noChanges()} loading={loading} btnHandler={handleSaveQuestion} />
      </QuestionEdit>
    </>
  );
};

export default QuestionRowEntContext;
