import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Typography } from '@mui/material';
import { slugify } from 'transliteration';
import ModalEditor from '../ModalEditor';

// eslint-disable-next-line react/prop-types
export default function ThemesModal({ open, handleClose, theme, setTheme, isAdd = false, loading, btnHandler }) {
  return (
    <ModalEditor open={open} handleClose={handleClose} size="sm">
      <Typography variant="subtitle1" textAlign="center">
        {theme.label || 'Тема'}
      </Typography>
      <Typography sx={{ opacity: 0.5 }} variant="body1" textAlign="center">
        [{theme.id}]
      </Typography>
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        <TextField
          fullWidth
          label="Название"
          value={theme.label}
          name="label"
          onChange={(e) =>
            setTheme({ ...theme, [e.target.name]: e.target.value, slug: `${theme.id}_${slugify(e.target.value)}` })
          }
        />
        <TextField
          fullWidth
          label="Название KZ"
          value={theme.label_kz}
          name="label_kz"
          onChange={(e) =>
            setTheme({ ...theme, [e.target.name]: e.target.value })
          }
        />
        <TextField
          fullWidth
          label="Раздел"
          value={theme.topic}
          name="topic"
          onChange={(e) => setTheme({ ...theme, [e.target.name]: e.target.value })}
        />
        <TextField
          fullWidth
          label="Раздел KZ"
          value={theme.topic_kz}
          name="topic_kz"
          onChange={(e) => setTheme({ ...theme, [e.target.name]: e.target.value })}
        />
        {!isAdd && (
          <TextField
            fullWidth
            label="Слаг"
            value={theme.slug}
            name="slug"
            onChange={(e) => setTheme({ ...theme, [e.target.name]: e.target.value })}
          />
        )}
        <TextField
          fullWidth
          label="Метки"
          helperText="Вводите метки через запятую"
          value={theme.props}
          name="props"
          onChange={(e) => setTheme({ ...theme, [e.target.name]: e.target.value })}
        />
      </Stack>
      <LoadingButton fullWidth variant="contained" sx={{ marginTop: 2 }} loading={loading} onClick={btnHandler}>
        {isAdd ? 'Добавить' : 'Сохранить'}
      </LoadingButton>
    </ModalEditor>
  );
}
