/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import React from 'react';
import RichEditor from '../RichEditor';

export default function QuestionSolution({ value, onChange }) {
  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Решение
      </Typography>
      <RichEditor value={value} onChange={onChange} />
    </Box>
  );
}
