import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import Iconify from '../Iconify';
import ThemesAddButton from './ThemesAddButton';
import ThemesItem from './ThemesItem';
import ThemesModal from './ThemesModal';
import ThemesSortable from './ThemesSortable';

export default function ThemesEditor({ subject = {}, refreshThemes }) {
  const [ordering, setOrdering] = useState(false);


  return (
    <>
      <Stack direction="row" sx={{ marginTop: 3 }} spacing={2}>
        <ThemesAddButton themesLength={subject?.themes?.length || 0} subjectId={subject.id} refreshThemes={refreshThemes} />
        <Button size="large" onClick={() => setOrdering(!ordering)} variant="outlined">
          {ordering ? 'Отмена' : 'Поменять порядок'}
        </Button>
      </Stack>

      <TableContainer component={Card} sx={{ marginTop: 2 }}>
        <Table size="small" sx={{ minWidth: 650 }}>
          <TableHead sx={{ boxShadow: 4 }}>
            {ordering ? (
              <TableRow>
                <TableCell colSpan={4} align='center'>Перемещайте темы за ползунок слева</TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Название</TableCell>
                <TableCell align="center">Метки</TableCell>
                <TableCell align="center">{''}</TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {ordering && <ThemesSortable refreshThemes={refreshThemes} disableOrdering={() => setOrdering(false)} ordering={ordering} themes={subject?.themes || []} />}
            {!ordering && subject?.themes?.map((theme, index) => <ThemesItem refreshThemes={refreshThemes} key={index} theme={theme} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

/*
{subject?.themes?.map((theme, index) => (
              <ThemesItem ordering={ordering} key={index} theme={theme} />
            ))}
            */
