import { TextField } from '@mui/material';

const UsersSearch = ({ search, onSearch }) => {
  return (
    <TextField
      value={search}
      onChange={onSearch}
      variant="outlined"
      style={{ width: 300 }}
      placeholder="(имя, email, телефон)"
      label='Поиск'
    />
  );
};

export default UsersSearch;
