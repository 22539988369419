/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, ButtonGroup, Grid, Popover, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Iconify from './Iconify';

RichEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

const editorStyle = {
  outline: 'none',
  padding: '15px',
  borderRadius: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  fontSize: '1rem',
  transition: '0.4s ease',
};

export default function RichEditor({ value, onChange, style }) {
  const editor = useRef();
  const [isFocus, setIsFocus] = useState(false);

  const [activeImg, setActiveImg] = useState(null);
  const [activeImgStyle, setActiveImgStyle] = useState({});
  const [editOpen, setEditOpen] = useState(false);

  const [ratioActive, setRatioActive] = useState(true);

  const imgHandler = (e) => {
    // MathType
    if (window.mathType && Object.keys(window.mathType).length) window.mathType.target = e.target;

    if (e.target.tagName === 'IMG') {
      setActiveImg(e.target);
      const rect = e.target.getBoundingClientRect()
      setEditOpen(true);
      setActiveImgStyle({
        width: `${rect.width}`,
        height: `${rect.height}`,
        align: e.target.style.margin || '0px auto 0px 0px',
        ratio: [rect.width, rect.height],
      });
    }
  };

  const pasteHandler = (e) => {
    e.preventDefault();

    if (!sessionStorage.getItem('copyMediaSrc')) {
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, text);
      return;
    }

    const div = new DOMParser().parseFromString(sessionStorage.getItem('copyMediaSrc'), "text/xml").firstChild;

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    range.collapse(false);
    range.insertNode(div);

    // делаем, чтобы курсор был после вставленной картинки
    selection.removeAllRanges();
    range.setStartAfter(div);
    selection.addRange(range);

    sessionStorage.setItem('copyMediaSrc', '');
    editor.current.blur();
  };

  const saveImgEdit = () => {
    const { width, height, align } = activeImgStyle;
    activeImg.style.width = `${width}px`;
    activeImg.style.height = `${height}px`;
    activeImg.style.margin = align;
    console.log(activeImgStyle, activeImg);

    onChange(editor.current.innerHTML);

    setEditOpen(false);
  };

  const handlerFocus = (e) => {
    setIsFocus(true);
    console.log('focus', e);
    const toolbar = document.getElementById('toolbar-mathType');
    if (toolbar) {
      const editor = e.target;
      const rect = editor.getBoundingClientRect();
      toolbar.classList.add('active');
      toolbar.style.top = `${rect.y - 50 + window.scrollY}px`;
      toolbar.style.left = `${rect.x}px`;
      toolbar.style.pointerEvents = 'all';
    }
  };
  const handlerBlur = (e) => {
    setIsFocus(false);
    onChange(editor.current.innerHTML);

    const toolbar = document.getElementById('toolbar-mathType');
    if (toolbar) {
      toolbar.classList.remove('active');
      setTimeout(() => {
        if (!toolbar.classList.contains('active'))
        toolbar.style.pointerEvents = 'none';
      }, 100);
    }
  };

  useLayoutEffect(() => {
    editor.current.innerHTML = value;
  }, [value]);

  return (
    <>
      <div
        className="richEditor"
        ref={editor}
        style={{ ...editorStyle, ...style, boxShadow: isFocus ? 'inset 0px 0px 0px 2px #2065D1' : 'none' }}
        suppressContentEditableWarning="true"
        onBlur={handlerBlur}
        onFocusCapture={handlerFocus}
        contentEditable="true"
        onClick={imgHandler}
        onPaste={pasteHandler}
      />
      <Popover
        anchorEl={editor.current}
        open={editOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container direction="column" spacing={2} sx={{ padding: 2, maxWidth: '300px' }}>
          <Grid sx={{ display: 'flex' }} item alignItems="center">
            <TextField
              value={activeImgStyle.width}
              onChange={(e) => {
                if (ratioActive) {
                  const { ratio } = activeImgStyle;
                  setActiveImgStyle({
                    ...activeImgStyle,
                    height: Math.round((e.target.value * ratio[1]) / ratio[0]),
                    width: e.target.value,
                  });
                } else setActiveImgStyle({ ...activeImgStyle, width: e.target.value });
              }}
              label="Ширина"
              variant="standard"
            />
            <ToggleButton
              sx={{ margin: '0 10px' }}
              value="check"
              size="small"
              selected={ratioActive}
              onChange={() => {
                setRatioActive(!ratioActive);
              }}
            >
              <Iconify icon="material-symbols:aspect-ratio" width={24} height={24} />
            </ToggleButton>
            <TextField
              value={activeImgStyle.height}
              onChange={(e) => {
                if (ratioActive) {
                  const { ratio } = activeImgStyle;
                  setActiveImgStyle({
                    ...activeImgStyle,
                    width: Math.round((e.target.value * ratio[0]) / ratio[1]),
                    height: e.target.value,
                  });
                } else setActiveImgStyle({ ...activeImgStyle, height: e.target.value });
              }}
              label="Высота"
              variant="standard"
            />
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              fullWidth
              value={activeImgStyle.align}
              exclusive
              disabled={activeImgStyle.display === 'inline'}
              onChange={(e, value) => setActiveImgStyle({ ...activeImgStyle, align: value })}
            >
              <ToggleButton value="0px auto 0px 0px">
                <Iconify icon="akar-icons:text-align-left" width={24} height={24} />
              </ToggleButton>
              <ToggleButton value="0px auto">
                <Iconify icon="akar-icons:text-align-center" width={24} height={24} />
              </ToggleButton>
              <ToggleButton value="0px 0px 0px auto">
                <Iconify icon="akar-icons:text-align-right" width={24} height={24} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
              <Button onClick={saveImgEdit} variant="contained">
                <Iconify icon="line-md:confirm" width={24} height={24} />
              </Button>
              <Button onClick={() => setEditOpen(false)}>
                <Iconify icon="ci:close-small" width={24} height={24} />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
