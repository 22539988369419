/* eslint-disable react/prop-types */
import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import QuestionPreview from './QuestionPreview';

export default function QuestionPreviewModal({ preview, setPreview, fields, themeLabel }) {
  return (
    <Modal open={preview} onClose={() => setPreview(false)}>
      <Box
        sx={{
          width: '70vw',
          maxWidth: 'none',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          overflowY: 'auto',
          paddingRight: '20px'
        }}
        className='test-preview'
      >
        <Typography variant="h4" align="center" color="white" gutterBottom>
          Предпросмотр вопроса
        </Typography>

        <QuestionPreview
          question={fields.question}
          answers={fields.answers}
          correct={fields.correct}
          solution={fields.solution}
          themeLabel={themeLabel}
        />
      </Box>
    </Modal>
  );
}
