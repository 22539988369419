import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';

const palette = [
  { value: '#ff0000', label: 'Красный' },
  { value: '#0000ff', label: 'Синий' },
  { value: '#00ff00', label: 'Зеленый' },
  { value: 'yellow', label: 'Желтый' },
  { value: 'cyan', label: 'Голубой' },
  { value: 'lime', label: 'Лайм' },
  { value: 'gray', label: 'Серый' },
  { value: 'orange', label: 'Оранжевый' },
  { value: 'purple', label: 'Пурпурный' },
  { value: 'black', label: 'Черный' },
  { value: 'white', label: 'Белый' },
  { value: 'pink', label: 'Розовый' },
  { value: 'darkblue', label: 'Темно-синий' },
];

const ColorSelect = ({ value, onChange, name, label }) => (
  <Box>
    <Typography color='white'>{label}</Typography>
    <Select sx={{ background: 'white' }} name={name} value={value?.properties[name]} onChange={onChange}>
    {palette.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        <Stack direction="row">
          <Box sx={{ background: value, width: 24, height: 24, borderRadius: 0.5, marginRight: 1 }} />
          <Typography>{label}</Typography>
        </Stack>
      </MenuItem>
    ))}
  </Select>
  </Box>
);

export default ColorSelect;
