import { Grid } from '@mui/material';
import QuestionsStatus from '../components/dashboard/QuestionsStatus';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <QuestionsStatus />
        </Grid>
      </Grid>
    </Page>
  );
}
