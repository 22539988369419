/* eslint-disable react/prop-types */
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';
import RichEditor from '../RichEditor';

export default function QuestionAnswers({ answers, answersCorrect, onChangeAnswer, handleAddAnswer, handleMarkCorrect, handleDeleteAnswer }) {
  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Ответы
        <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={handleAddAnswer}>
          Добавить
        </Button>
      </Typography>
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        {answers.map((el, index) => (
          <Stack alignItems={'center'} direction="row" key={index} spacing={2} className={answersCorrect.includes(index) ? 'btn--correctAnswer' : ''}>
            <RichEditor
              key={index}
              style={{ minWidth: '200px' }}
              value={el}
              onChange={(html) => onChangeAnswer(html, index)}
            />
            <Tooltip disableInteractive title="Правильный ответ">
              <IconButton
                sx={{ height: 'fit-content' }}
                className={'markCorrect'}
                onClick={() => handleMarkCorrect(index)}
              >
                <Iconify icon="icon-park-solid:correct" width={24} height={24} />
              </IconButton>
            </Tooltip>
            <Tooltip disableInteractive title="Удалить ответ">
              <IconButton
                sx={{ height: 'fit-content' }}
                className="btn--deleteAnswer"
                onClick={() => handleDeleteAnswer(index)}
              >
                <Iconify icon="fluent:delete-16-filled" width={24} height={24} />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
