/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import {
  QuestionAnswers,
  QuestionEdit,
  QuestionHead,
  QuestionInput,
  QuestionPreviewModal,
  QuestionSolution,
  QuestionTop,
  SaveButton,
  SubjectInput,
  ThemeInput,
} from '../questionItem';

const QuestionRowNuet = ({
  headless = false,
  loading,
  handleSendData,
  subjectsInfo,

  // FIELDS
  id = 0,
  subjectID = 0,
  themeID = 0,
  question = '',
  solution = '',
  answers = [],
  correct = [],
  disable = false,
}) => {
  const [isExpand, setIsExpand] = useState(!!headless);

  const initObject = {
    subjectID,
    themeID,
    question,
    solution,
    answers,
    correct,
    disable,
  };

  const [fields, setFields] = useState(initObject);

  // UTILS
  const handleSaveQuestion = () => {
    const data = {
      id,
      question: fields.question,
      subjectID: fields.subjectID,
      themeID: fields.themeID,
      answers: JSON.stringify(fields.answers),
      correct: JSON.stringify(fields.correct),
      solution: fields.solution,
      disable: fields.disable,
    };
    console.log('save data nuet', data);
    handleSendData(data)
  };
  const resetQuestionData = () => {
    setFields(initObject);
  };
  const noChanges = () =>
    fields.subjectID === subjectID &&
    fields.themeID === themeID &&
    fields.question === question &&
    fields.solution === solution &&
    fields.answers.toString() === answers.toString() &&
    fields.correct.toString() === correct.toString() &&
    fields.disable === disable;

  // HANDLES
  const handleQuestion = (html) => {
    setFields({ ...fields, question: html });
  };
  const handleSubjectID = (e) => {
    const newSubjectID = +e.target.value;
    const theme = subjectsInfo.themes.filter((x) => x.parent === newSubjectID)[0];

    setFields({ ...fields, subjectID: newSubjectID, themeID: theme.id });
  };
  const handleThemeID = (e, newValue) => {
    if (newValue) setFields({ ...fields, themeID: newValue.id });
  };

  const handleAnswers = (html, index) => {
    const newState = fields.answers.map((ans, i) => (i === index ? html : ans));
    setFields({ ...fields, answers: newState });
  };
  const handleAnswersAdd = () => setFields({ ...fields, answers: [...fields.answers, 'Новый ответ'] });
  const handleAnswersDelete = (index) => {
    // remove correct mark
    let newCorrect = fields.correct
    if (fields.correct.includes(index)) {
      newCorrect = fields.correct.filter((i) => i !== index);
    }

    const newState = fields.answers.filter((el, i) => i !== index);
    setFields({ ...fields, answers: newState, correct: newCorrect });
  };
  const handleAnswersMark = (index) => {
    if (fields.correct.includes(index)) {
      const newState = fields.correct.filter((i) => i !== index);
      setFields({ ...fields, correct: newState });
    } else setFields({ ...fields, correct: [...fields.correct, index] });
  };

  const handleSolution = (html) => {
    setFields({ ...fields, solution: html });
  };
  const handleDisable = () => {
    setFields({ ...fields, disable: !fields.disable });
  };

  // Preview
  const [preview, setPreview] = useState(false);

  const getThemeLabel = (target) => {
    const theme = subjectsInfo.themes?.filter((t) => t.id === target);
    if (!theme.length) return '';
    return theme[0].label;
  };

  return (
    <>
      {!headless && (
        <QuestionHead
          id={id}
          themeID={themeID}
          themeLabel={subjectsInfo.themes?.filter((t) => t.id === themeID)[0]?.label || ''}
          subject={subjectsInfo.subjects.filter((x) => x.id === subjectID)[0]}
          question={question}
          disable={disable}
          setIsExpand={setIsExpand}
          isExpand={isExpand}
        />
      )}
      <QuestionEdit disable={fields.disable} isExpand={isExpand} scrollbar={headless}>
        <QuestionTop
          label={`Редактирование вопроса НУЕТ ID-${id}`}
          headlessLabel={'Добавление вопроса НУЕТ'}
          headless={headless}
          handleChangeDisable={handleDisable}
          handleResetQuestion={resetQuestionData}
          handlePreview={() => setPreview(true)}
        />
        <QuestionPreviewModal preview={preview} setPreview={setPreview} fields={fields} themeLabel={getThemeLabel(fields.themeID)} />

        <QuestionInput value={fields.question} onChange={handleQuestion} />

        <Stack spacing={2} direction="row" alignItems="center">
          <SubjectInput value={fields.subjectID} onChange={handleSubjectID} options={subjectsInfo.subjects} />
          <ThemeInput
            value={fields.themeID}
            onChange={handleThemeID}
            options={subjectsInfo.themes
              .filter((theme) => theme.parent === fields.subjectID && !theme.props.includes('noTest'))
              .map((option) => ({
                id: option.id,
                label: `${option.id} - ${option.label}`,
              }))}
          />
        </Stack>

        <QuestionAnswers
          answers={fields.answers}
          answersCorrect={fields.correct}
          onChangeAnswer={handleAnswers}
          handleAddAnswer={handleAnswersAdd}
          handleDeleteAnswer={handleAnswersDelete}
          handleMarkCorrect={handleAnswersMark}
        />

        <QuestionSolution value={fields.solution} onChange={handleSolution} />

        <SaveButton disabled={noChanges()} loading={loading} btnHandler={handleSaveQuestion} />
      </QuestionEdit>
    </>
  );
};

export default QuestionRowNuet;
