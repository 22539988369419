import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { LoadingButton } from '@mui/lab';
import { TableCell, TableRow } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import DraggableItem from './DraggableItem';

export default function ThemesSortable({ themes, disableOrdering, refreshThemes }) {
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(themes);
  }, [themes]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((x) => x.id === active.id);
        const newIndex = items.findIndex((x) => x.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const addNotify = useContext(NotifyContext);
  const [loadingUpdate, fetchOrderTheme] = useAjax((data, error) => {
    if (error) {
      console.warn(data.error);
      addNotify(data.msg || 'Ошибка при обновлении', 'error');
      return;
    }

    console.log(data);
    addNotify(`Порядок тем успешно изменен`, 'success');
    disableOrdering();
    refreshThemes();
  });
  const handleChangeOrder = () => {
    const order = items.map((t) => t.id);
    console.log(order);
    fetchOrderTheme('themes/order', 'POST', { order });
  };

  const measuring = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      measuring={measuring}
    >
      <SortableContext items={items}>
        <TableRow>
          <TableCell colSpan={4}>
            <LoadingButton loading={loadingUpdate} onClick={handleChangeOrder} fullWidth variant="outlined">
              Сохранить
            </LoadingButton>
          </TableCell>
        </TableRow>
        {items.map((theme, index) => (
          <DraggableItem key={theme.id} theme={theme} />
        ))}
      </SortableContext>
    </DndContext>
  );
}
