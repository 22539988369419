/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import '@wiris/mathtype-generic/wirisplugin-generic';

export default function MathTypeEditor({ style }) {
  useEffect(() => {
    const genericIntegrationProperties = {};
    genericIntegrationProperties.target = document.getElementById('default-mathType');
    genericIntegrationProperties.toolbar = document.getElementById('toolbar-mathType');
    // eslint-disable-next-line no-undef
    const genericIntegrationInstance = new WirisPlugin.GenericIntegration(genericIntegrationProperties);

    window.mathType = genericIntegrationInstance;
    window.mathType.init();
    genericIntegrationInstance.listeners.fire('onTargetReady', {});
    // document.getElementById('toolbar-mathType').onmousedown = () => {return false}
  }, []);

  return (
    <>
      <div style={{ display: 'none' }} id="default-mathType" />
      <div
        id="toolbar-mathType"
        onClick={() => document.getElementById('toolbar-mathType').classList.remove('active')}
        style={style}
      />
      <style>{`
          #chemistryIcon {
            display: none;
          }
          #toolbar-mathType {
            opacity: 0;
            pointer-events: none;
            z-index: 9999;

            position: absolute;
            background-color: #2065d1;
            border-radius: 8px;
            box-shadow: 0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 14%), 0px 1px 10px 0px rgb(145 158 171 / 12%);
            transition: 0.6s ease;
          }
          #toolbar-mathType:hover {
            background-color: #0C53B7;
          }
          #toolbar-mathType.active {
            opacity: 1;
          }
          #toolbar-mathType img {
            padding: 10px;
            filter: brightness(100);
          }
          .wrs_modal_button_cancel,
          .wrs_modal_close_button,
          .wrs_tickContainer {
            display: none;
          }
        `}</style>
    </>
  );
}
