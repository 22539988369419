/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable react/prop-types */
import { Button, Stack, TextField, styled } from '@mui/material';
import arc from 'arc';
import { Marker } from 'maplibre-gl';
import { useEffect, useRef, useState } from 'react';

import Iconify from '../../components/Iconify';
import { useApi } from '../../hooks/useApi';
import { useNotify } from '../../hooks/useNotify';
import DateRange from './Input/DateRange';
import DescriptionInput from './Input/DescriptionInput';
import TypeSelect from './Input/TypeSelect';

import ColorSelect from './Input/ColorSelect';
import ImagePicker from './Input/ImagePicker';
import NumberInput from './Input/NumberInput';

const createGeo = (type, lngLat, props) => ({
  type: 'Feature',
  geometry: {
    type,
    coordinates: lngLat,
  },
  properties: {
    title: '',
    hash: '0',
    hash_end: '0',
    type: 'symbol',
    ...props,
  },
});

const nullGeo = {
  geometry: {
    coordinates: [],
    type: '',
  },
  properties: {
    type: '',
    title: '',
    description: '',
  },
};
const editSource = 'source-edit';
const EditMode = ({ map, popup, sx, activeGeo, onClose, isAdd, events, mutateChangeGeojson }) => {
  const [loading, api] = useApi();
  const [geo, setGeo] = useState(activeGeo || nullGeo);
  const geoRef = useRef(geo);
  const markers = useRef([]);
  const hoveredFeature = useRef(null);
  const { notify } = useNotify();

  const removeMarkers = () => {
    markers.current.forEach((x) => x.remove());
    markers.current = [];
  };

  const clearMap = () => {
    removeMarkers();
    const prevSource = { ...map.current.getSource('source-edit')._data };
    prevSource.features = [];
    map.current.getSource('source-edit').setData(prevSource);
  };

  const typeChangeHandler = (value) => {
    const [type, geometryType] = value.split(':');
    setGeo({
      ...nullGeo,
      properties: { ...nullGeo.properties, type },
      geometry: { ...nullGeo.geometry, type: geometryType },
    });
  };

  const closeHandler = () => {
    onClose();
    clearMap();
  };

  const getFeatures = (e) => map.current.queryRenderedFeatures(e.point).filter((f) => f.source === 'source-edit');

  const loadGeoJson = (obj) => {
    const { geometry, properties } = obj;
    geoRef.current.geometry.coordinates = geometry.coordinates;
    const prevSource = { ...map.current.getSource('source-edit')._data };
    prevSource.features = [];
    removeMarkers();

    if (geometry.type === 'Point') {
      prevSource.features = [obj];
    } else if (geometry.type === 'LineString') {
      prevSource.features = [obj];

      geometry.coordinates.forEach((lngLat, i) => {
        const element = document.createElement('div');
        element.className = 'marker';
        element.style.width = `${20}px`;
        element.style.height = `${20}px`;
        element.style.backgroundColor = 'white';
        element.style.border = '1px solid gray';
        element.style.borderRadius = `${20}px`;

        if (properties.type === 'icon' && i === 0) {
          element.style.width = `${60}px`;
          element.style.height = `${60}px`;
          element.style.borderRadius = `${60}px`;
          element.style.backgroundImage = `url(${properties.image})`;
          element.style.backgroundSize = 'cover';
          element.style.backgroundPosition = 'center';
        }

        const marker = new Marker({ draggable: true, element }).setLngLat(lngLat).addTo(map.current);

        marker.on('drag', (e) => {
          const prevSource = { ...map.current.getSource('source-edit')._data };
          prevSource.features[0].geometry.coordinates[i] = e.target._lngLat.toArray();
          map.current.getSource('source-edit').setData(prevSource);
        });
        markers.current.push(marker);
      });
    } else if (geometry.type === 'Polygon') {
      prevSource.features = [obj];
      (geometry.coordinates[0] || []).forEach((lngLat, i) => {
        if (geometry.coordinates[0].length > 2 && i === geometry.coordinates[0].length - 1) return;

        const marker = new Marker({ element: getPolygonEditPoint(), draggable: true }).setLngLat(lngLat).addTo(map.current);
        marker.on('drag', (e) => {
          const prevSource = { ...map.current.getSource('source-edit')._data };
          const coordinates = prevSource.features[0].geometry.coordinates[0];

          prevSource.features[0].geometry.coordinates[0][i] = e.target._lngLat.toArray();
          if (i === 0)
            prevSource.features[0].geometry.coordinates[0][coordinates.length - 1] = e.target._lngLat.toArray();

          map.current.getSource('source-edit').setData(prevSource);
        });
        markers.current.push(marker);
      });
    }

    map.current.getSource('source-edit').setData(prevSource);
  };

  const resetHandler = () => {
    setGeo(activeGeo);
    loadGeoJson(activeGeo);
  };

  const styleHandler = (style) => {
    setGeo({ ...geo, properties: { ...geo.properties, style } });
  };

  const rangeHandler = (value) => {
    setGeo({ ...geo, properties: { ...geo.properties, ...value } });
  };

  const propsHandler = (e) => {
    setGeo({ ...geo, properties: { ...geo.properties, [e.target.name]: e.target.value } });
  };

  const clickHandler = (e) => {
    const { lngLat } = e;

    if (geoRef.current.geometry.type === 'Point') {
      loadGeoJson(createGeo('Point', lngLat.toArray(), geoRef.current.properties));
    }
    if (geoRef.current.geometry.type === 'LineString') {
      const prevSource = { ...map.current.getSource(editSource)._data };
      const newGeometry = [...(prevSource.features[0]?.geometry?.coordinates || []), lngLat.toArray()];

      loadGeoJson(createGeo('LineString', newGeometry, geoRef.current.properties));
    }
    if (geoRef.current.geometry.type === 'Polygon') {
      const prevSource = { ...map.current.getSource(editSource)._data };
      const coordinates = prevSource.features[0]?.geometry?.coordinates[0] || [];

      if (coordinates.length > 3) coordinates.pop();

      const newGeometry = [[...coordinates, lngLat.toArray()]];

      if (newGeometry[0].length >= 3) newGeometry[0].push(coordinates[0]);

      loadGeoJson(createGeo('Polygon', newGeometry, geoRef.current.properties));
    }
  };

  useEffect(() => {
    geoRef.current = geo;
    loadGeoJson(geoRef.current);
    console.log(geo);
  }, [geo]);

  useEffect(() => {
    setGeo(activeGeo || nullGeo);
  }, [activeGeo]);

  useEffect(() => {
    map.current._canvasContainer.style.cursor = 'auto';

    // map.current.on('mousedown', (e) => {
    //   const features = getFeatures(e);
    //   console.log(features);
    // });

    map.current.on('mousemove', (e) => {
      const features = getFeatures(e);
      if (features.length > 0) {
        const feature = features[0];
        hoveredFeature.current = feature;

        map.current._canvasContainer.style.cursor = 'pointer';

        // Description
        if (feature.properties.description) {
          popup.current
            .setLngLat(e.lngLat)
            .setHTML(`<div class="map-popup">${feature.properties.description}</div>`)
            .addTo(map.current);
        }

        map.current.setFeatureState({ source: editSource, id: hoveredFeature.current.id }, { hover: true });
      } else if (hoveredFeature.current) {
        map.current.setFeatureState({ source: editSource, id: hoveredFeature.current.id }, { hover: false });
        hoveredFeature.current = null;
        map.current._canvasContainer.style.cursor = 'auto';

        popup.current.remove();
      }
    });

    map.current.on('click', clickHandler);

    return () => {
      map.current.off('mousedown');
      map.current.off('mousemove');
      map.current.off('click', clickHandler);
    };
  }, []);

  const saveHandler = () => {
    if (!activeGeo) return;

    const data = map.current.getSource(editSource)._data.features[0];

    const { hash, hash_end } = data.properties;

    if (!hash || !hash_end || +hash > +hash_end) return notify('Укажите правильный временной отрезок', 'error');

    api(`geojson/${activeGeo.properties.id}`, {
      method: 'PUT',
      body: data,
      success: () => {
        notify('GeoJson успешно сохранен', 'success');
        mutateChangeGeojson(data, 'edit');
        closeHandler();
      },
      error: (e) => {
        notify('Ошибка при сохранении GeoJson', 'error');
      },
    });
  };

  const addHandler = () => {
    const data = map.current.getSource(editSource)._data.features[0];

    if (data.properties.type === 'icon') {
      const { properties, geometry } = data
      
      data.properties.route = JSON.stringify({
        type: "FeatureCollection",
        features: [
          {
            id: Date.now(),
            type: 'Feature',
            geometry: {
              coordinates: geometry.coordinates,
              type: 'LineString'
            },
            properties: {}
          }
        ]
      })
      
      data.geometry.type = 'Point'
      data.geometry.coordinates = geometry.coordinates[0]
    }

    api(`geojson`, {
      method: 'POST',
      body: data,
      success: () => {
        notify('GeoJson успешно добавлен', 'success');
        mutateChangeGeojson(data, 'add');
        closeHandler();
      },
      error: (e) => {
        notify('Ошибка при добавлении GeoJson', 'error');
      },
    });
  };

  return (
    <>
      <EditWrapper direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <TypeSelect value={geo} onChange={typeChangeHandler} />
          <TextField
            value={geo.properties.title}
            onChange={(e) => setGeo({ ...geo, properties: { ...geo.properties, title: e.target.value } })}
            placeholder="Название"
            sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
          />

          {geo.properties.type !== 'symbol' && <DescriptionInput value={geo} setValue={setGeo} />}
        </Stack>

        <Stack direction="row" spacing={1}>
          {!isAdd && (
            <Button onClick={closeHandler} variant="contained" color="error">
              <Iconify icon="fluent:delete-16-filled" size={20} />
            </Button>
          )}
          <Button onClick={closeHandler} variant="contained" color="secondary">
            <Iconify icon="iconoir:cancel" size={24} />
          </Button>
          {!isAdd && (
            <Button disabled={loading} onClick={saveHandler} variant="contained">
              Сохранить
            </Button>
          )}
          {isAdd && (
            <Button disabled={loading} onClick={addHandler} variant="contained">
              Добавить
            </Button>
          )}
        </Stack>

        <style>{`
      .maplibregl-popup-tip, .maplibregl-popup-close-button {
        display: none;
      }

      .maplibregl-popup-content {
        background: none;
        padding: 0;
        box-shadow: none;
        border-radius: 0px;
        pointer-events: none;
      }
      
      .map-popup {
        color: white;
        font-size: 16px;
        transform: translateY(50px);
      }
      `}</style>
      </EditWrapper>

      <EditWrapper
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        <DateRange
          range={geo.properties}
          onChange={rangeHandler}
          options={events.map((x) => ({ value: x.id.toString(), label: `${x.id} - ${x.title}` }))}
        />

        <Stack direction="row" spacing={1}>
          {['dot', 'dashed', 'polygon', 'symbol', 'line'].includes(geo.properties.type) && (
            <ColorSelect label="Цвет" value={geo} name="color" onChange={propsHandler} />
          )}
          {['polygon'].includes(geo.properties.type) && (
            <ColorSelect label="Цвет обводки" value={geo} name="color_outline" onChange={propsHandler} />
          )}
          {['symbol'].includes(geo.properties.type) && (
            <NumberInput label="Размер текста" value={geo} name="text_size" onChange={propsHandler} />
          )}
          {['symbol'].includes(geo.properties.type) && (
            <NumberInput label="Поворот текста" value={geo} name="text_rotate" onChange={propsHandler} />
          )}
          {['icon'].includes(geo.properties.type) && <ImagePicker value={geo} name="image" onChange={propsHandler} />}
        </Stack>
      </EditWrapper>
    </>
  );
};

export default EditMode;

const EditWrapper = styled(Stack)(() => ({
  padding: 10,
  background: 'rgba(0, 0, 0, 0.2)',
  width: '100%',
  backdropFilter: 'blur(2px)',
}));

const smoothness = (coordinates, radius = 5) => {
  const newCoordinates = [];

  for (let i = 0; i < coordinates.length; i++) {
    if (i === 0) {
      newCoordinates.push(coordinates[i]);
      continue;
    }

    const point = (p) => ({ x: p[0], y: p[1] });

    const generator = new arc.GreatCircle(point(coordinates[i - 1]), point(coordinates[i]));
    newCoordinates.push(...generator.Arc(200, { offset: 10 }).geometries[0].coords);
  }

  return newCoordinates;
};

const getPolygonEditPoint = () => {
  const element = document.createElement('div');
  element.className = 'marker';
  element.style.width = `${10}px`;
  element.style.height = `${10}px`;
  element.style.backgroundColor = 'white';
  element.style.border = '1px solid gray';
  element.style.borderRadius = `${10}px`;
  return element
};
