import { useCallback, useLayoutEffect, useState } from 'react';

const storageUser = 'userToken';

export const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem(storageUser) || null);

  const login = useCallback(async (jwtToken, save = true) => {
    setToken(jwtToken);

    if (save) {
      localStorage.setItem(storageUser, jwtToken);
      console.log('save storage', jwtToken);
    }
  }, []);
  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem(storageUser);
  }, []);

  return { login, logout, token };
};
