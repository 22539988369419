import { Box, TextField, Typography } from '@mui/material';

const NumberInput = ({ value, onChange, name, label }) => (
  <Box>
    <Typography color="white">{label}</Typography>
    <TextField
      type="number"
      sx={{ '& .MuiInputBase-root': { background: 'white' } }}
      value={value?.properties[name]}
      name={name}
      onChange={onChange}
    />
  </Box>
);

export default NumberInput;
