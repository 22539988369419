import { Container, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Page from '../../components/Page';
import { ThemesEditor } from '../../components/subjects';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';

export default function Themes() {
  const addNotify = useContext(NotifyContext);
  const { subjectSlug } = useParams();
  const [searchParams] = useSearchParams();
  const test = searchParams.get('test');

  const [subject, setSubject] = useState({});
  const [loadingSubject, fetchSubject] = useAjax((data, error) => {
    if (error) {
      addNotify(data.msg || 'Ошибка при обновлении', 'error');
      console.log(data.error);
      return;
    }

    console.log(data);
    setSubject(data);
  });
  const updateSubjectThemes = () => fetchSubject(`subjects/${subjectSlug}/themes?lesson&test=${test}`);

  useEffect(() => {
    updateSubjectThemes();
  }, []);

  return (
    <Page title={subject.label}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center" divider={<span style={{ opacity: 0.5 }}>—</span>}>
            <Link href="/subjects" color="inherit" underline="none" variant="h4">
              Предметы
            </Link>
            <Typography variant="h4" sx={{ background: subject.color, ...colorLinkStyle }}>
              {subject.label}
            </Typography>
          </Stack>
        </Stack>

        {loadingSubject ? <LinearProgress /> : <ThemesEditor subject={subject} refreshThemes={updateSubjectThemes} />}
      </Container>
    </Page>
  );
}

const colorLinkStyle = {
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
};
