/* eslint-disable max-classes-per-file */
// material
import { Stack, Typography } from '@mui/material';
// components
import 'maplibre-gl/dist/maplibre-gl.css';
import Page from '../../components/Page';

import MapPreview from './MapPreview';

export default function MapEditor() {
  return (
    <Page title="MapEditor">
      <Stack direction="row" spacing={3} alignItems="center" sx={{ marginBottom: 3 }}>
        <Typography variant="h4" gutterBottom>
          Карта
        </Typography>
      </Stack>

      <MapPreview height={800} />
    </Page>
  );
}
