/* eslint-disable prefer-template */
/* eslint-disable no-extra-boolean-cast */
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNotify } from '../../hooks/useNotify';
import Iconify from '../../components/Iconify';
import { JARYQ_TESTS_API } from '../../config';
import { useApi } from '../../hooks/useApi';
import useGetApi from '../../hooks/useGetApi';
import UserDetailsModal from './UserDetailsModal';
import './index.css';

const rowsPerPage = 15;
const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'login', label: 'Логин', alignRight: false },
  { id: 'fullName', label: 'Имя', alignRight: false },
  { id: 'phone', label: 'Телефон', alignRight: false },
  { id: 'city', label: 'Город', alignRight: false },
  { id: 'school', label: 'Школа', alignRight: false },
  { id: 'schoolClass', label: 'Класс', alignRight: false },
  { id: 'status', label: 'Статус теста' },
  { id: 'tools', label: '' },
];

const paramsToQuery = (obj) =>
  Object.keys(obj).reduce((res, key) => (!!obj[key] ? res + `&${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}` : res), '');

export default function UsersTable() {
  const [params, setParams] = useState({ search: '', city: '', school: '', schoolClass: '', psql: '' });
  const [rawSearch, setRawSearch] = useState('');
  const [page, setPage] = useState(0);
  const { notify } = useNotify();

  const [loadingCities, api] = useApi();
  const [citiesData, setCities] = useState({});

  const [userDetailsID, setUserDetailsID] = useState(null);

  const [loading, users, mutate] = useGetApi(
    `admin/users?page=${page}&limit=${rowsPerPage}${paramsToQuery(params)}`,
    {
      rows: [],
    },
    {
      origin: JARYQ_TESTS_API,
      error: (err) => {
        notify(err.message, 'error');
      }
    }
  );

  useEffect(() => {
    api('admin/citiesData', {
      success: ({ cities }) => {
        setCities(cities);
      },
      origin: JARYQ_TESTS_API,
    });
  }, []);

  const onParamHandler = (key, v) => {
    setParams({ ...params, [key]: v });
  };

  const onMutateUser = (user) => {
    if (!user) return;

    const newRows = users.rows.map((x) => (x.id === user.id ? { ...user } : x));
    mutate({ ...users, rows: newRows });
  };

  const isEmpty = users.rows.length === 0;

  const getTestStatus = (user) => {
    if (user.testStatus?.active === true) return <Chip size="small" color="warning" label="проходит" />;
    if (user.testStatus?.active === false)
      return (
        <Chip
          size="small"
          color={'success'}
          label={`${user.testStatus?.score}/${user.testStatus?.maxScore} - прошёл`}
        />
      );
    return <Chip size="small" label="не прошёл" />;
  };

  const onPSQLFindHandler = () => {
    setParams({ ...params, psql: rawSearch });
  };

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <UserDetailsModal
        mutateUser={onMutateUser}
        user={users.rows.find((x) => x.id === userDetailsID)}
        closeModal={() => setUserDetailsID(null)}
      />

      <Stack direction="row" justifyContent="space-between" sx={{ paddingY: 2 }}>
        <Stack direction="row" spacing={1}>
          <TextField
            size="small"
            label="Поиск (имя, телефон)"
            value={params.search}
            onChange={(e) => onParamHandler('search', e.target.value)}
            disabled={params.psql !== ''}
          />
          <Autocomplete
            size="small"
            sx={{ width: '20ch' }}
            value={params.city}
            renderInput={(params) => <TextField {...params} label="Город" />}
            options={[
              // { value: '', label: 'Все' },
              ...Object.keys(citiesData).map((x) => ({ value: x, label: x })),
            ]}
            onChange={(_, v) => onParamHandler('city', v?.value)}
            disabled={params.psql !== ''}
          />
          <Autocomplete
            size="small"
            sx={{ width: '20ch' }}
            value={params.school}
            renderInput={(params) => <TextField {...params} label="Школа" />}
            options={
              citiesData[params.city]
                ? [
                    // { value: '', label: 'Все' },
                    ...Object.keys(citiesData[params.city]).map((x) => ({ value: x, label: x })),
                  ]
                : []
            }
            onChange={(_, v) => onParamHandler('school', v?.value)}
            disabled={params.psql !== ''}
          />
          <SchoolClassPicker
            sx={{ width: '30ch' }}
            value={params.schoolClass}
            onChange={(_, v) => onParamHandler('schoolClass', v)}
            disabled={params.psql !== ''}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            sx={{ width: '50ch' }}
            className='json-editor '
            size="small"
            placeholder="PSQL WHERE"
            value={rawSearch}
            onChange={(e) => setRawSearch(e.target.value)}
          />
          <Button size="small" variant="outlined" onClick={onPSQLFindHandler}>
            <Iconify size={20} icon="carbon:search-advanced" />
          </Button>
        </Stack>
      </Stack>
      <Table className="questionsTable" size="small">
        <TableHead>
          <TableRow>
            {TABLE_HEAD.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'} sx={{ whiteSpace: 'nowrap' }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          ) : (
            users.rows.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.login}</TableCell>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                  <TooltipTextShort>{user.city}</TooltipTextShort>
                </TableCell>
                <TableCell>
                  <TooltipTextShort>{user.school}</TooltipTextShort>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{user.schoolClass}</TableCell>
                <TableCell>{getTestStatus(user)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setUserDetailsID(user.id)}>
                    <Iconify icon="tabler:dots" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>

        {isEmpty && (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                <Paper sx={{ paddingY: 2, boxShadow: 'none' }}>
                  <Typography gutterBottom align="center" variant="subtitle1">
                    По данному запросу ничего не найдено
                  </Typography>
                </Paper>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <TablePagination
        component="div"
        count={users?.totalUsers || 0}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        page={page}
        onPageChange={(_, v) => setPage(v)}
      />
    </TableContainer>
  );
}

const SchoolClassPicker = ({ sx, value, onChange, disabled }) => (
  <ToggleButtonGroup sx={sx} value={value} exclusive disabled={disabled} fullWidth onChange={onChange}>
    <ToggleButton size="small" value="4">
      <Typography>4</Typography>
    </ToggleButton>
    <ToggleButton size="small" value="5">
      <Typography>5</Typography>
    </ToggleButton>
    <ToggleButton size="small" value="8">
      <Typography>8</Typography>
    </ToggleButton>
    <ToggleButton size="small" value="9">
      <Typography>9</Typography>
    </ToggleButton>
    <ToggleButton size="small" value="10">
      <Typography>10</Typography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const TooltipTextShort = ({ children }) => (
  <Tooltip title={children}>
    <Typography
      variant="body"
      component="p"
      sx={{ width: 100, height: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {children}
    </Typography>
  </Tooltip>
);
