import { LoadingButton } from '@mui/lab';
import { Box, Stack, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import ModalEditor from '../ModalEditor';
import TestToggle from '../TestToggle';

// eslint-disable-next-line react/prop-types
export default function SubjectsModal({ open, handleClose, subject, setSubject, isAdd = false, updateSubject, loadingUpdate }) {
  return (
    <ModalEditor open={open} handleClose={handleClose} size="sm">
      <Typography variant="subtitle1" textAlign="center">
        {subject.label || 'Предмет'}
      </Typography>
      <Typography sx={{ opacity: 0.5 }} variant="body1" textAlign="center">
        [{subject.slug}]
      </Typography>
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        {isAdd && (
          <>
          <TestToggle fullWidth value={subject.test} onChange={(e, val) => setSubject({ ...subject, test: val })} />
          <TextField
            fullWidth
            label="Слаг"
            value={subject.slug}
            name="slug"
            onChange={(e) => setSubject({ ...subject, [e.target.name]: e.target.value })}
          /></>
        )}
        <TextField
          fullWidth
          label="Название"
          value={subject.label}
          name="label"
          onChange={(e) => setSubject({ ...subject, [e.target.name]: e.target.value })}
        />
        <ColorPicker>
          <TextField
            fullWidth
            label="Цвет"
            value={subject.color}
            name="color"
            onChange={(e) => setSubject({ ...subject, [e.target.name]: e.target.value })}
          />
          <ColorThumb sx={{ background: subject.color }} />
        </ColorPicker>
      </Stack>
      <LoadingButton fullWidth variant="contained" sx={{ marginTop: 2 }} loading={loadingUpdate} onClick={updateSubject}>
        Сохранить
      </LoadingButton>
    </ModalEditor>
  );
}

const ColorPicker = styled(Box)(() => ({
  position: 'relative',
}));
const ColorThumb = styled(Box)(() => ({
  width: 30,
  height: 30,
  borderRadius: 30,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: 10,
  pointerEvents: 'none',
}));
