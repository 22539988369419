import { createContext, useEffect, useRef, useState } from 'react';
import { Typography, Card } from '@mui/material';
import Iconify from '../components/Iconify';

export const NotifyContext = createContext();

export const NotifyProvider = ({ children }) => {
  const [notifies, setNotifies] = useState([]);

  const addPushNotify = (message, type) => {
    const id = new Date().valueOf();
    setNotifies([...notifies, <Notify key={id} message={message} type={type} />]);
  };

  return (
    <NotifyContext.Provider value={addPushNotify}>
      <div style={{ ...wrapper }}>{notifies}</div>
      {children}
    </NotifyContext.Provider>
  );
};

const Notify = ({ message, type = '' }) => {
  let color = 'rgb(0 0 0 / 80%)';
  let icon = 'eva:info-outline';
  if (type === 'success') {
    color = 'rgb(21 128 13 / 80%)';
    icon = 'ep:success-filled';
  }
  if (type === 'error') {
    color = 'rgb(189 17 17 / 80%)';
    icon = 'codicon:error';
  }

  const notify = useRef();
  const deleteNotify = () => {
    notify.current.remove();
  };

  const [offsetX, setOffsetX] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [clickX, setClickX] = useState(null);
  const startGrab = (e) => {
    setClickX(e.clientX);
  };
  const moveGrab = (e) => {
    if (clickX === null) return;
    const diff = clickX - e.clientX;
    if (diff > 0) {
      setOffsetX(diff);
      setOpacity(opacity - diff / 5000);
    }
  };
  const stopGrab = () => {
    setClickX(null);
  };

  useEffect(() => {
    notify.current.style.opacity = opacity;
    notify.current.style.transform = `translateX(${-offsetX}px)`;
    if (opacity < 0) deleteNotify();
  }, [opacity]);
  useEffect(() => {
    const timer = setTimeout(() => {
      deleteNotify();
    }, 3000);
    return () => clearTimeout(timer)
  }, []);

  return (
    <Card
      ref={notify}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 3,
        backgroundColor: color,
        backdropFilter: 'blur(2px)',
        cursor: 'grab',
        transform: `translateX(0px)`,
        marginTop: '10px'
      }}
      onMouseDown={startGrab}
      onMouseMove={moveGrab}
      onMouseUp={stopGrab}
    >
      <Iconify icon={icon} color="white" width={24} height={24} />
      <Typography variant="subtitle" sx={{ marginLeft: 2, color: 'white', userSelect: 'none' }}>
        {message}
      </Typography>
    </Card>
  );
};

const wrapper = {
  position: 'fixed',
  bottom: '30px',
  right: '30px',
  zIndex: 9999,
};
