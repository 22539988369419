import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useState } from 'react';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
// component
import { AuthContext } from '../../contexts/AuthContext';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useAjax } from '../../hooks/useAjax';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const auth = useContext(AuthContext);

  const addNotify = useContext(NotifyContext);

  const [loadingSubmit, fetchAuth] = useAjax((data, error) => {
    if (error) {
      addNotify(data.msg || 'Ошибка при входе', 'error');
      console.warn(data?.error);
      return;
    }

    if (data.role.slug === 'student' || data.role.slug === 'special-student') {
      addNotify('Доступ запрещен', 'error');
      return;
    }

    if (window.PasswordCredential) {
      const cr = new window.PasswordCredential({ id: values.email, password: values.password });
      navigator.credentials.store(cr);
    }

    addNotify('Вы успешно вошли', 'success');
    auth.login(data.token);
    console.log(data, auth);
  });

  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string().email('Email должен быть правильным').required('Заполните поле'),
  //   password: Yup.string().required('Заполните поле'),
  // });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    // validationSchema: LoginSchema,
    onSubmit: () => {
      fetchAuth('auth/login', 'POST', { email: values.email, password: values.password });
    },
  });

  const { errors, handleSubmit, getFieldProps, values } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="on" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Пароль"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
        </Stack>

        <LoadingButton
          sx={{ marginTop: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loadingSubmit}
        >
          Войти
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
