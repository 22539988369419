/* eslint-disable react/prop-types */
import { MenuItem, TextField } from '@mui/material';
import React from 'react';

export default function SubjectInput({ value, onChange, options, disabled }) {
  return (
    <TextField
      sx={{ width: '30ch' }}
      select
      label="Предмет"
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
