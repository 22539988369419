import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

const sounds = [
  {
    value: null,
    label: 'Нету',
  },
  { value: 'https://jaryq-academy.kz/history-map/attack.mp3', label: 'Нападение' },
];

const SoundSelect = ({ value, onChange, name, label }) => (
  <FormControl>
    {!!label && <InputLabel id={`sound-select-label-${name}`}>{label}</InputLabel>}
    <Select label={label} name={name} value={value[name]} onChange={onChange}>
      {sounds.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          <Typography>{label}</Typography>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SoundSelect;
