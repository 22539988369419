/* eslint-disable react/prop-types */
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export default function ThemeInput({ value, onChange, options }) {
  console.log({options});
  return (
    <Autocomplete
      disablePortal
      value={value.toString()}
      onChange={onChange}
      options={[ { label: 'Без темы', id: -1 }, ...options]}
      sx={{ width: '30ch', display: 'inline-block' }}
      renderInput={(params) => <TextField {...params} label="ID темы" />}
      isOptionEqualToValue={(option, value) => option.label.toLowerCase().includes(value)}
    />
  );
}
