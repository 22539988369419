import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  colors,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useGetApi from '../../hooks/useGetApi';
import { NotifyContext } from '../../contexts/NotifyContext';
import { useApi } from '../../hooks/useApi';

const CreateProbeLink = () => {
  const [openBottomBar, setBottomBar] = useState(false);
  const [link, setLink] = useState('');
  const [form, setForm] = useState({
    leadId: '',
    subjects: [],
    expiredIn: 4,
    lang: '',
  });
  const [loadingSubjects, subjects] = useGetApi('subjects?test=ent', [])
  const [loading, api] = useApi();
  const addNotify = useContext(NotifyContext);

  const createLinkHandler = () => {
    const { leadId, subjects, expiredIn, lang } = form;

    if (!leadId || !subjects.length || !lang) {
      addNotify('Заполните все поля', 'info');
      return;
    }

    setBottomBar(true);
    const body = {
      leadId: +leadId.trim(),
      subjects,
      expiredIn: expiredIn * 24 * 60 * 60 * 1000 + Date.now(),
      lang,
    };

    api('probe/create', {
      body,
      method: 'POST',
      success: (link) => {
        setLink(link);
        addNotify('Ссылка успешно создана', 'success');
      },
      error: (e) => {
        console.error(e);
        setLink('')
        setBottomBar(false)
        addNotify('Ошибка при создании ссылки', 'error');
      },
    });
  };

  return (
    <Card>
      <Card sx={{ padding: 2, backgroundColor: colors.deepPurple[400], color: 'white' }}>
        <Typography variant="h6">Создание временной ссылки на пробный тест</Typography>
        <Divider sx={{ marginTop: 1, marginBottom: 3, backgroundColor: 'whitesmoke', opacity: 0.5 }} />
        <Stack direction="row" spacing={4}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Input
                label="ID заявки AMOCRM"
                sx={{ width: '25ch', color: 'white' }}
                value={form.leadId}
                onChange={(e) => {
                  console.log(e.target.value);
                  // eslint-disable-next-line no-restricted-globals
                  if (isNaN(e.target.value)) return;
                  setForm({ ...form, leadId: e.target.value });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ color: 'white' }} position="start">
                      ID
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl sx={{ width: '25ch' }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: 'white',
                    '&.Mui-focused': {
                      color: 'white',
                    },
                  }}
                >
                  Язык
                </InputLabel>
                <SelectInput
                  labelId="demo-simple-select-label"
                  value={form.lang}
                  label="Язык"
                  onChange={(e) => {
                    setForm({ ...form, lang: e.target.value });
                  }}
                >
                  <MenuItem value={'ru'}>Русский</MenuItem>
                  <MenuItem value={'kz'}>Казахский</MenuItem>
                </SelectInput>
              </FormControl>
            </Stack>
            <Box>
              <Typography>Длительность доступа (дни)</Typography>
              <Slider
                valueLabelDisplay="auto"
                value={form.expiredIn}
                onChange={(e) => {
                  setForm({ ...form, expiredIn: e.target.value });
                }}
                step={1}
                marks
                min={1}
                max={20}
                sx={{
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#fff',
                    borderColor: 'gray',
                  },
                  '& .MuiSlider-mark': {
                    backgroundColor: 'gray',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: '#fff',
                  },
                }}
              />
            </Box>

            <Button disabled={loading} onClick={createLinkHandler} color="info" variant="contained">
              Создать
            </Button>
          </Stack>
          <Grid container sx={{ width: '80ch' }}>
            {subjects.map((subj, i) => (
              <Grid item xs={4} key={i}>
                <FormControlLabel
                  label={subj.label}
                  control={
                    <Checkbox
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      }}
                      checked={form.subjects.includes(subj.slug)}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        if (!e.target.checked) {
                          setForm({ ...form, subjects: form.subjects.filter((x) => x !== subj.slug) });
                        } else setForm({ ...form, subjects: [...form.subjects, subj.slug] });
                      }}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Card>
      <Card
        sx={{
          textAlign: 'center',
          transition: '0.6s ease',
          maxHeight: openBottomBar ? 100 : 0,
          padding: openBottomBar ? 2 : 0,
        }}
      >
        {link && !loading ? (
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(`https://app.jaryq-academy.kz/probe/${link}`);
            }}
          >
            Кликните, чтобы скопировать: https://app.jaryq-academy.kz/probe/{link}
          </Typography>
        ) : (
          <CircularProgress />
        )}
      </Card>
    </Card>
  );
};

const SelectInput = styled(Select)({
  '& fieldset': {
    borderColor: 'white',
    opacity: 0.2,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white !important',
    opacity: 0.2,
  },
  '&.Mui-focused fieldset': {
    borderColor: 'white !important',
    opacity: 0.2,
  },
  '& .MuiSelect-icon': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
});

const Input = styled(TextField)({
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
      opacity: 0.2,
      transition: '0.4s ease',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      opacity: 0.2,
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      opacity: 1,
    },
    '& .MuiTypography-root': {
      color: 'white',
      opacity: 0.5,
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
  },
});

export default CreateProbeLink;
