/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import React from 'react';
import RichEditor from '../RichEditor';

export default function QuestionInput({ value, onChange }) {
  return (
    <Box>
      <Typography sx={{ marginBottom: 1 }} variant="h6" component="div">
        Вопрос
      </Typography>

      <RichEditor style={{ marginTop: 0 }} value={value} onChange={onChange} />
    </Box>
  );
}
