import React, { useState } from 'react';
import { Card, Tab, Tabs, Typography } from '@mui/material';
import Iconify from '../Iconify';

import 'react-dropzone-uploader/dist/styles.css';
import ImagesSection from './ImagesSection';
import VideosSection from './VideosSection';

// eslint-disable-next-line react/prop-types
const ImageManagerContent = ({ closeManager, isCopyBuffer }) => {
  const [tab, setTab] = useState(0);

  return (
    <Card sx={{ padding: 3, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
      <style>{`
        .imageManager__item {
          position: relative;
        }
        .imageManager__item::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          opacity: 0;
          background: #eeeeee;
          transition: 0.4s ease;
          z-index: 2;
          pointer-events: none;
        }
        .imageManager__item:hover::after {
          opacity: 0.7;
        }
        .imageManager__item-name {
          width: 128px;
          padding: 0 5px;
          padding-top: 5px;
          font-size: 13px;
          line-height: 15px;
          font-weight: 500;
          display: -webkit-box;
          text-align: center;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          opacity: 0.7;
          pointer-events: none;
        }
      `}</style>
      <Typography align="center" gutterBottom variant="h5" component="div">
        Менеджер медиа
      </Typography>
      <Tabs value={tab} centered onChange={(e, v) => setTab(v)} sx={{ marginBottom: 2 }}>
        <Tab label="Изображения" />
        <Tab label="Видео" />
      </Tabs>
      <ImagesSection isCopyBuffer={isCopyBuffer} sx={{ display: tab === 0 ? 'block' : 'none' }} closeManager={closeManager} />
      <VideosSection isCopyBuffer={isCopyBuffer} sx={{ display: tab === 1 ? 'block' : 'none' }} closeManager={closeManager} />
    </Card>
  );
};

export default function ImageManager({ isCopyBuffer = false }) {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ ...wrapper, transform: !open ? `translateX(100%)` : 'none' }}>
      <Card style={{ ...controller }} onClick={() => setOpen(!open)}>
        {!open ? (
          <Iconify sx={{ display: 'block' }} icon="bi:image-fill" width={24} height={24} />
        ) : (
          <Iconify sx={{ display: 'block' }} icon="akar-icons:arrow-right" width={24} height={24} />
        )}
      </Card>
      <ImageManagerContent isCopyBuffer={isCopyBuffer} closeManager={() => setOpen(false)} />
    </div>
  );
}

const wrapper = {
  position: 'fixed',
  top: '50px',
  right: '0',
  zIndex: 9999,
  transition: '0.5s ease',
};

const controller = {
  position: 'absolute',
  top: '20px',
  right: '100%',
  height: 'fit-content',
  padding: '10px',
  backgroundColor: 'white',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  zIndex: 2,
  cursor: 'pointer',
};
